import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import Modal from 'react-awesome-modal';
import classNames from 'classnames';
import {YMaps,Map,ZoomControl,Placemark} from 'react-yandex-maps';

// components
import Loading from '../../Loading';
import Error from '../../Error';
import Close from '../../Close';
import Alert,{alertShow} from '../../Alert';
import Scroller from '../../Scroller';
import OrderInfo from '../../OrderInfo';
// components local
import Couriers from './Couriers.js';

// models
import {Areas,Orders,Events,OrderStatuses,OrderItems,Settings,Users,Clients,CourierOrders,OrderMoneyReports,CourierRoutes,DayShifts,Pushes,MoneyTransfers,CourierRides,Refusers,Accountings,Logs,CourierStatuses,PrinterJobs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateNow,dateGet,moneyFormat,phoneFormatter,todayGet,tsDay} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {MAP,orderStatus,orderStatusName,eventType,timers,userType,userStatus,orderType,comonStatus,orderMoneyStatus,moneyTransferType,orderPickup,accountingType,accountingDirection,courierStatus,printerJobType,orderPaid} from '../../../Globals/Constants';

// styles
import styles from './DashboardLogist.module.css';
const cx = classNames.bind(styles);

class DashboardLogist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			order:null,
			client:null,
			areas:[],
			dayshift:null,
			orders:[],
			inwork:[],
			inworkold:[],
			ordersall:[],
			orderscook:[],
			couriers:[],
			courierorders:[],
			courierstatuses:[],
			courier:null,
			route:[],
			activearea:null,
			amount:undefined,
			amountTransfer:undefined,
			debts:[],
			isOnline:0,
			report:null,
			courierIncome:null,
			ride:null,
			ridecurrent:null,
			amountFocus:false,
			amountTransferFocus:false,
			dailyReport:null,
			cashPaper:5000,
			cashCoin:300,
			noCouriersOnline:false,
			modalshow:false,
			modalshowcourier:false,
			modalshowcouriermap:false,
			modalshoworderinfo:false,
			modalshowchange:false,
			modalshowcalc:false,
			modalshowpromptlogist:false,
			modalshowtokassa:false,
			modalshowclosekassa:false,
			modalshowcashin:false,
			modalshowstat:false,
			ordersinwork:false,
			showallcouriers:false,
			showallorders:false,
			showallmap:false,
			showcookorders:false,
			isswitch:false,
			isordersave:false,
			iscourierchange:false,
			iserror:false,
			loading:true,
			loadinginner:true,
			loadingmodal:true,
			currentOrder:null,
			loadCurrentOrder:true
		};
	}
	timerId = null;
	courierTimerId = null;
	map = null;
	ymaps = null;
	cookStatuses = [
		orderStatus.COOK_WAIT,
		orderStatus.COOK_ACCEPTED,
		orderStatus.COOK_DONE,
		orderStatus.COLLECTOR_WAIT,
		orderStatus.COLLECTOR_ACCEPTED,
		orderStatus.COLLECTOR_DONE
	];
	allowStatuses = [
		orderStatus.OPERATOR_WAIT,
		orderStatus.COOK_WAIT,
		orderStatus.COOK_ACCEPTED,
		orderStatus.COOK_DONE,
		orderStatus.COLLECTOR_WAIT,
		orderStatus.COLLECTOR_ACCEPTED,
		orderStatus.COLLECTOR_DONE,
		orderStatus.LOGIST_WAIT,
		orderStatus.LOGIST_ACCEPTED,
		orderStatus.LOGIST_DONE,
		orderStatus.COURIER_WAIT,
		orderStatus.COURIER_ACCEPTED,
		orderStatus.COURIER_IN_ROUTE,
		orderStatus.COURIER_DONE,
		orderStatus.FINISH,
		orderStatus.ARCHIVE
	];
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const isswitch = ls('isswitch');
		this.setState({isswitch:!!isswitch});
		this.dataGet();
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		this.timerId = null;
		this.courierTimerStop();
		document.removeEventListener('keydown', this.handleKeyDown);
	}
	courierTimerStop = () => {
		clearInterval(this.courierTimerId);
		this.courierTimerId = null;
	}
	dataGet = async () => {
		this.setState({loading:true,iserror:false});
		const user = ls('user');
		try {
			const activearea = ls('activearea');
			const settings = ls('settings');
			const users = await Users.getByType([userType.COURIER]);
			const noCouriersOnline = parseInt(Settings.getByKey('couriers-no-online-status', settings).value) === 1;
			const couriersList = users.data.filter(f => f.status === userStatus.ACTIVE && (noCouriersOnline ? (f.online === 0 || f.online === 1) : f.online === 1) && (f.areaId === 0 || (f.areaId !== 0 && f.areaId === activearea.id)));
			const areas = await Areas.getAllActive();
			const couriers = [];
			for (const courier of couriersList) {
				if(!courier.online) {
					couriers.push({ ...courier, position: { lat: null, lon: null }, rideData: { duration: null, distance: null } });
					continue;
				}
				try {
					const positions = await CourierRoutes.getLastRoute(courier.id);
					if (positions.data && positions.data.length > 0) {
						const validPositions = positions.data.filter((pos) => pos.latitude && pos.longitude);
						if (validPositions.length > 0) {
							const { latitude, longitude } = validPositions[0];
							couriers.push({ ...courier, position: { lat: latitude, lon: longitude }, area: areas.data.find(item => item.id === courier.areaId), rideData: { duration: null, distance: null } });
							continue;
						}
					}
				}
				catch (error) {
					console.error(`Error fetching positions for courier ${courier.id}: ${error}`);
				}
				couriers.push({ ...courier, position: { lat: null, lon: null } });
			}
			const dayshift = await DayShifts.getCurrent(activearea.id);
			this.cashInShow(dayshift);
			this.setState({
				user,
				couriers,
				areas:areas.data,
				activearea,
				dayshift
			}, async () => {
				this.ordersUpdate();
				this.timerId = setInterval(this.ordersUpdate, timers.ORDERS_GET);
			});
			Settings.getCode('courier-income', (data) => {
				if (data !== null) this.setState({courierIncome:parseFloat(data.value)});
			});
		} catch (ex) {
			this.setState({loading:false,iserror:true});
		}
	}
	ordersUpdate = async () => {
		const statuses = [
			orderStatus.OPERATOR_WAIT,
			orderStatus.COOK_WAIT,
			orderStatus.COOK_ACCEPTED,
			orderStatus.COOK_DONE,
			orderStatus.COLLECTOR_WAIT,
			orderStatus.COLLECTOR_ACCEPTED,
			orderStatus.COLLECTOR_DONE,
			orderStatus.LOGIST_WAIT,
			orderStatus.LOGIST_ACCEPTED,
			orderStatus.LOGIST_DONE,
			orderStatus.COURIER_WAIT
		];
		const {activearea,dayshift} = this.state;
		const ordersday = await Orders.getDayShiftOrders(activearea.id, dayshift.id);
		const o = ordersday.data.filter(f => (f.date === null || f.date < (todayGet() + tsDay)) && f.isPickup === orderPickup.DELIVERY);
		const ordersdate = await Orders.getOnDateGet(todayGet());
		const od = ordersdate.data.filter(f => f.dayShiftId !== dayshift.id && statuses.includes(f.status) && f.areaId === activearea.id && f.isPickup === orderPickup.DELIVERY);
		const orders = [...o,...od];
		if (orders) {
			const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-logist', settings).value);
			const o1 = orders.filter(f => [orderStatus.LOGIST_WAIT,orderStatus.LOGIST_ACCEPTED,orderStatus.LOGIST_DONE].includes(f.status));
			o1.sort((a) => (a.dateCreate + timeDelay) < now ? -1 : 1);
			const o2 = orders.filter(f => [orderStatus.COURIER_WAIT,orderStatus.COURIER_ACCEPTED,orderStatus.COURIER_IN_ROUTE,orderStatus.COURIER_DONE,orderStatus.FINISH].includes(f.status));
			o2.sort((a,b) => a.dateCreate > b.dateCreate ? -1 : 1);
			const o3 = orders.filter(f => this.cookStatuses.includes(f.status));
			o3.sort((a,b) => a.dateCreate > b.dateCreate ? -1 : 1);
			await this.couriersUpdate();
			this.setState({orders:o1,ordersall:o2,orderscook:o3,loading:false});
			const inwork = await OrderMoneyReports.inWorkGet(activearea.id);
			const inworkold = inwork.data.filter(f => f.dayShiftId !== dayshift.id);
			this.setState({inwork:inwork.data,inworkold});
		}
		else this.setState({loading:false,iserror:true});
	}
	couriersUpdate = async () => {
		const {couriers} = this.state;
		const ids = [];
		for (let i = 0, j = couriers.length; i < j; i++) {
			ids.push(couriers[i].id);
		}
		const oldorders = await Orders.reports.ondatenow();
		const orders = await CourierOrders.getAllByCourierAsync(ids);
		if (orders.data) {
			for (let i = 0, j = couriers.length; i < j; i++) {
				const courierId = couriers[i].id;
				const ordersArray = Object.values(orders.data[courierId].data[0]);
				ordersArray.forEach((v) => {
					const order = oldorders.data.find(f => f.id === v.orderId);
					v.isdate = order ? true : false;
				});
				couriers[i].orders = ordersArray;
			}
		}
		couriers.sort((a,b) => a.orders.length > + b.orders.length ? -1 : 1);
		couriers.sort((a,b) => {
			if (a.online === b.online) return a.orders.length > b.orders.length ? -1 : 1;
			return a.online ? -1 : 1;
		});
		this.setState({couriers}, async () => {
			const ids = couriers.map(f => f.id);
			const courierstatuses = await CourierStatuses.getLast(ids);
			const debts = await Accountings.byUserIdsGet(ids);
			this.setState({debts:debts.data,courierstatuses:courierstatuses.data});
		});
	}
	additionalStatus = (order) => {
		const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-logist', settings).value);
		return (order.dateCreate + timeDelay) < now && order.status === orderStatus.LOGIST_WAIT ? styles.alarm : (order.status === orderStatus.LOGIST_DONE ? styles.paid : null);
	}
	handleSelect = (e) => {
		const t = e.target, userId = parseInt(t.value);
		if (userId === 0 || isNaN(userId)) return;
		const courier = {
			userId,
			userName:t.options[t.selectedIndex].text,
			phone:t.options[t.selectedIndex].getAttribute('phone'),
			orderId:this.state.order.id,
			amount:this.state.courierIncome||this.state.order.deliveryCost,
			status:comonStatus.ACTIVE
		};
		this.setState({courier});
	}
	orderSelectChange = async (order, ischange) => {
		let client = null;
		if (order.clientId) {
			const c = await Clients.getAsync(order.clientId);
			client = c.data[0];
		}
		const products = await OrderItems.get(order.id);
		order.orderproducts = products.data;
		this.setState({order,client}, () => {
			if (!ischange) this.orderAccept(order);
			this.modalChangeShow();
		});
	}
	courierSelect = (c) => {
		const courier = {
			userId:c.id,
			userName:c.name,
			phone:c.phone,
			orderId:this.state.order.id,
			amount:this.state.courierIncome||this.state.order.deliveryCost,
			status:comonStatus.ACTIVE
		};
		this.setState({courier});
	}
	orderSelect = async (order) => {
		const {activearea} = this.state;
		let client = null;
		if (order.clientId) {
			const c = await Clients.getAsync(order.clientId);
			client = c.data[0];
		}
		const products = await OrderItems.get(order.id);
		order.orderproducts = products.data;
		this.setState({order,client,courier:null,route:[{latitude:activearea.latitude,longitude:activearea.longitude},{latitude:order.latitude,longitude:order.longitude}]}, () => this.modalShow());
	}
	orderInfoShow = () => this.setState({modalshoworderinfo:true});
	orderAccept = (order) => {
		if (order.status === orderStatus.LOGIST_WAIT) {
			const data = {status:orderStatus.LOGIST_ACCEPTED}, {user} = this.state;
			Orders.update(order.id, data, () => {
				Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
				this.ordersUpdate();
			});
		}
	}
	orderDone = () => {
		const {user,courier,activearea,iscourierchange,order,courierIncome,ride} = this.state;
		//const order = iscourierchange ? this.state.order.order : this.state.order;
		if (iscourierchange) {
			if (courier === null || courier.userId === order.courierId) {
				this.modalHide();
				return;
			}
			courier.orderId = order.id;
			courier.amount = courierIncome||order.deliveryCost;
		} else {
			if (courier === null) {
				alertShow('Выберите курьера!', true);
				return;
			}
		}
		if (this.state.isordersave) return;
		this.setState({isordersave:true});
		const data = {
			status:orderStatus.COURIER_WAIT,
			courierId:courier.userId,
			courierName:courier.userName,
			courierPhone:courier.phone
		};
		Orders.update(order.id, data, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
			this.ordersUpdate();
		});
		if (!iscourierchange) {
			const statuses = {
				orderId:order.id,
				userId:user.id,
				userName:user.name,
				status:data.status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		}
		if (iscourierchange) {
			CourierOrders.removeByOrder(order.id, (res) => {
				Events.add(user.id, Texts.courier_orders.remove, JSON.stringify({orderId:order.id}), null, order.id, eventType.COURIER_ORDERS);
				CourierOrders.add(courier, (res) => Events.add(user.id, Texts.courier_orders.add, null, JSON.stringify(data), res.data.id, eventType.COURIER_ORDERS));
			});
			Accountings.byOrderIdUpdate(order.id, {userId:courier.userId,userName:courier.userName});
		} else CourierOrders.add(courier, (res) => Events.add(user.id, Texts.courier_orders.add, null, JSON.stringify(data), res.data.id, eventType.COURIER_ORDERS));
		if (order.type !== orderType.OPERATOR_HALL && order.isPickup !== orderPickup.PICKUP) {
			if (ride) {
				const r = {
					userId:courier.userId,
					orderId:order.id,
					duration:ride.time.plain,
					durationTraffic:ride.jamsTime.plain,
					distance:ride.length.plain,
					forecastTime:ride.forecastTime,
					forecastDelay:ride.forecastDelay,
					forecastDate:ride.forecastDate,
					data:JSON.stringify(ride)
				};
				CourierRides.add(r);
			}
			if (!order.isPaid) {
				if (!iscourierchange) {
					const priceFull = order.surrender || order.priceFull;
					const debt = {
						areaId:order.areaId,
						dayShiftId:order.dayShiftId,
						orderId:order.id,
						amount:order.priceFull,
						amountFull:priceFull,
						debt:priceFull,
						surrender:priceFull - order.priceFull,
						userId:courier.userId,
						userName:courier.userName,
						creatorUserId:user.id,
						creatorUserName:user.name,
						direction:accountingDirection.CREDIT,
						type:accountingType.COURIER,
						status:comonStatus.ACTIVE
					};
					Accountings.add(debt);
				}
			}
			const route = {
				userId:courier.userId,
				orderId:order.id,
				latitude:activearea.latitude,
				longitude:activearea.longitude
			};
			CourierRoutes.add(route, (res) => Events.add(user.id, Texts.courier_route.add, null, JSON.stringify(route), res.data.id, eventType.COURIER_ROUTE));
			const report = {
				areaId:activearea.id,
				dayShiftId:order.dayShiftId,
				courierId:courier.userId,
				courierName:courier.userName,
				userId:user.id,
				userName:user.name,
				orderId:order.id,
				status:orderMoneyStatus.IN_WORK,
				orderAmount:order.priceFull,
				isPaid:order.isPaid
			};
			OrderMoneyReports.add(report, (res) => Events.add(user.id, Texts.order_money_report.add, null, JSON.stringify(report), res.data.id, eventType.ORDER_MONEY)).then((res) => {
				if (iscourierchange && res.data.id) {
					const data = {
						courierId:report.courierId,
						orderId:report.orderId
					}
					OrderMoneyReports.removeExceptCourier(data, () => {
						Events.add(user.id, Texts.order_money_report.remove, JSON.stringify({orderId: order.id}), null, order.id, eventType.ORDER_MONEY);
					});
				}
			});
			setTimeout(() => this.ordersUpdate(), 1000);
		}
		this.modalHide();
		this.setState({isordersave:false});
		alertShow('Заказ передан курьеру');
		if (!iscourierchange) Pushes.client.done(order.clientId);
		Pushes.courier.neworder(courier.userId);
		this.modalReportHide();
	}
	orderFinish = () => {
		this.orderFinishUpdate(this.state.order, () => setTimeout(() => this.ordersUpdate(), 1000));
		this.modalHide();
		alertShow('Заказ завершен');
	}
	orderFinishUpdate = (order, callback) => {
		const {user} = this.state;
		const data = {status:orderStatus.FINISH,dateFinish:dateNow()};
		Orders.update(order.id, data, () => {
			try {
				Refusers.addOrder(order);
				Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER)
			}
			catch (err) {
				console.log(666, 'Refusers.addOrder', err);
			}
		});
		const statuses = {
			orderId:order.id,
			userId:user.id,
			userName:user.name,
			status:data.status
		};
		OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		if (callback) callback();
	}
	courierChange = () => this.setState({iscourierchange:true}, async () => await this.orderSelectChange(this.state.report.order, true));
	modalShow = () => this.setState({modalshow:true});
	modalHide = () => this.setState({modalshow:false,modalshoworderinfo:false,modalshowcouriermap:false,modalshowcourier:false,modalshowpromptlogist:false,courier:null,order:null,iscourierchange:false,isordersave:false});
	modalMapHide = () => this.setState({modalshowcouriermap:false,ride:null,ridecurrent:null}, () => this.courierTimerStop());
	modalReportHide = () => this.setState({modalshowcalc:false,report:null,isOnline:0,amount:''}, () => document.removeEventListener('keydown', this.handleKeyDown));
	modalChangeShow = () => this.setState({modalshowchange:true});
	modalChangeHide = () => this.setState({modalshow:false,modalshowchange:false,modalshoworderinfo:false,modalshowcouriermap:false,modalshowcourier:false,modalshowpromptlogist:false,courier:null,order:null,iscourierchange:false,isordersave:false});
	modalOrderInfoHide = () => this.setState({modalshoworderinfo:false});
	modalStatShow = () => this.setState({modalshowstat:true});
	modalStatHide = () => this.setState({modalshowstat:false});
	mapShow = () => {
		const {activearea,order} = this.state;
		this.setState({modalshowcouriermap:true,route:[{latitude:activearea.latitude,longitude:activearea.longitude},{latitude:order.latitude,longitude:order.longitude}]});
	}
	onMapLoadCouriers = (ymaps) => {
		this.ymaps = ymaps;
	}
	onMapLoad = (ymaps) => {
		this.ymaps = ymaps;
		this.mapRouteSet();
	}
	onOtherMapLoad = async (ymaps) => {
		this.ymaps = ymaps;
		try {
			const newCouriers = this.state.couriers.map(async (v) => {
				if(!v.online) {
					return { ...v, rideData: { duration: null, distance: null } };
				}
				const points = [];
				points.push({ lat: v.position.lat, lon: v.position.lon });
				v.orders.forEach((order) => {
					if (order.order.status !== 18) {
						points.push({ lat: order.order.latitude, lon: order.order.longitude });
					}
				});
				points.push({ lat: this.state.activearea.latitude, lon: this.state.activearea.longitude });
				const rideData = await this.checkTime(points);
				return { ...v, rideData };
			});
			await Promise.all(newCouriers).then(async (res) => {
				this.setState({ couriers: res }, () => this.couriersUpdate());
			});
		}
		catch (err) {
			console.error(err);
		}
	}
	mapRouteSet = () => {
		const ymaps = this.ymaps
		this.map.geoObjects.removeAll();
		ymaps.ready([]).then(() => {
			const coords = this.state.route.map((v,i) => ({type:'wayPoint',point:[v.latitude,v.longitude]}));
			ymaps.route(coords, {mapStateAutoApply:true,reverseGeocoding:true}).then((route) => {
				const ride = this.rideGet(route);
				ride.forecastTime = this.forecastGet(ride);
				ride.forecastDelay = this.forecastDelayGet(ride);
				ride.forecastDate = this.forecastDateGet(ride);
				const {ridecurrent} = this.state;
				if (ridecurrent) {
					ridecurrent.dateCalc = ridecurrent.forecastDate;
					ridecurrent.dateCurrent = ride.forecastDate;
					ridecurrent.time = this.forecastGet(ride);
					const diff = Math.abs(ridecurrent.dateCalc - ridecurrent.dateCurrent);
					ridecurrent.delay = Math.floor((diff)/60);
					const h = Math.floor(ridecurrent.delay/60), m = ridecurrent.delay % 60;
					ridecurrent.delayHuman = h ? `${h} ч ${m} мин` : `${m} мин`;
				}
				this.setState({ride})
				this.map.geoObjects.add(route)
			});
		});
	}
	rideGet = (route) => {
		const path = route.getPaths().get(0);
		const data = {
			length: {
				plain: path.getLength(),
				human: path.getHumanLength().replace('&#160;', ' ')
			},
			time: {
				plain: path.getTime(),
				human: path.getHumanTime().replace('&#160;', ' ')
			},
			jamsTime: {
				plain: path.getJamsTime(),
				human: path.getHumanJamsTime().replace('&#160;', ' ')
			},
			segments: []
		};
		const segments = path.getSegments();
		const steps = ['Трогаемся'];
		for (let i = 0, j = segments.length; i < j; i++) {
			const street = segments[i].getStreet();
			const lengthHuman = segments[i].getHumanLength().replace('&#160;', ' ');
			const humanAction = segments[i].getHumanAction();
			const segment = {
				action: {
					plain: segments[i].getAction(),
					human: humanAction
				},
				angle: segments[i].getAngle(),
				coordinates: segments[i].getCoordinates(),
				time: {
					plain: segments[i].getTime(),
					human: segments[i].getHumanTime().replace('&#160;', ' ')
				},
				length: {
					plain: segments[i].getLength(),
					human: lengthHuman
				},
				jamsTime: {
					plain: segments[i].getJamsTime(),
					human: segments[i].getHumanJamsTime().replace('&#160;', ' ')
				},
				street
			};
			data.segments.push(segment);
			steps.push(`Едем ${humanAction}${(street ? ' на ' + street : '')}, проезжаем ${lengthHuman}`);
		}
		steps.push('Останавливаемся');
		data.steps = steps;
		return data;
	}
	courierMapShow = async () => {
		const {order} = this.state, route = await this.courierRoutGet(),
			ridecurrent = order.status === orderStatus.COURIER_ACCEPTED || order.status === orderStatus.COURIER_IN_ROUTE ? await this.courierRideGet() : null;
		if (route) {
			this.setState({modalshowcouriermap:true,route,ridecurrent}, () => {
				if (order.status === orderStatus.COURIER_ACCEPTED || order.status === orderStatus.COURIER_IN_ROUTE)
					this.courierTimerId = setInterval(this.courierRoutUpdate, timers.COURIER_ROUTE_GET);
			});
		}
		else alertShow('Местоположение курьера не найдено!', true);
	}
	courierRoutUpdate = async () => {
		const route = await this.courierRoutGet();
		if (route) this.setState({route}, () => this.mapRouteSet());
	}
	courierRoutGet = async () => {
		const {order} = this.state, route = await CourierRoutes.get(order.id);
		return route && route.data && route.data.length ? [route.data[route.data.length-1], {latitude:order.latitude,longitude:order.longitude}] : null;
	}
	courierRideGet = async () => {
		const {order} = this.state, ride = await CourierRides.get(order.id);
		return ride && ride.data && ride.data.length ? ride.data[0] : null;
	}
	orderFinishOrderShow = async () => {
		const {order} = this.state.report;
		const products = await OrderItems.get(order.id);
		order.orderproducts = products.data;
		this.setState({modalshowcourier:true,order});
	}
	orderFinishSelect = (order) => {
		this.modalHide();
		this.setState({modalshowcalc:true,amount:'',isOnline:0,report:order}, () => document.addEventListener('keydown', this.handleKeyDown));
	}
	orderFinishSave = async () => {
		const {amount,isOnline,report,user} = this.state;
		const data = {
			status:orderMoneyStatus.FINISH,
			orderAmountFinal:amount,
			isOnline
		};
		//if (isOnline || amount === report.order.priceFull) Orders.update(report.orderId, {isPaid: 1}, (res) => {}, (err) => {})
		const order = {status:comonStatus.IN_ACTIVE, dateModify:Math.floor(Date.now() / 1000)};
		CourierOrders.update(report.orderId, order, () => Events.add(user.id, Texts.courier_orders.update, null, JSON.stringify(order), report.id, eventType.COURIER_ORDERS));
		OrderMoneyReports.update(report.id, data, () => Events.add(user.id, Texts.order_money_report.update, null, JSON.stringify(data), report.id, eventType.ORDER_MONEY));
		this.orderFinishUpdate(report.order, () => setTimeout(() => this.ordersUpdate(), 1000));
		this.modalReportHide();
		alertShow('Средства утчены были учтены. Заказ завершен!');
		if (isOnline) {
			const acc = await Accountings.byOrderIdGet(report.orderId);
			if (acc) {
				const accounting = acc.data[0];
				Logs.add(`Accounting delete by order ID: ${accounting.orderId}, status: ${accounting.status}`);
				if (accounting.status === comonStatus.ACTIVE)
					Accountings.remove(accounting.id);
			} else Logs.add(`Accounting not found by order ID: ${report.orderId}, mathod: orderFinishSave`);
		}
	}
	handleOnlineForm = (e) => {
		const isOnline = e.target.checked?1:0;
		const amount = isOnline ? this.state.report.order.priceFull : this.state.amount;
		this.setState({isOnline,amount});
	}
	handleKeyDown = (e) => {
		if (e.keyCode >= 48 && e.keyCode <= 57) this.numberSet(parseInt(String.fromCharCode(e.keyCode)));
		if (e.keyCode === 8) this.numberSet();
	}
	numberSet = (num) => {
		if (this.state.amountFocus) {
			if (num === undefined) this.setState({amount:this.state.amount?this.state.amount.slice(0,-1):this.state.amount});
			else {
				const amount = `${this.state.amount===undefined?'':this.state.amount}${num}`;
				if (amount.length < 8) this.setState({amount});
			}
		}
	}
	numberTransferSet = (num) => {
		if (this.state.amountTransferFocus) {
			if (num === undefined) this.setState({amountTransfer:this.state.amountTransfer?this.state.amountTransfer.slice(0,-1):this.state.amountTransfer});
			else {
				const amountTransfer = `${this.state.amountTransfer===undefined?'':this.state.amountTransfer}${num}`;
				if (amountTransfer.length < 8) this.setState({amountTransfer});
			}
		}
	}
	amountFocus = () => this.setState({amountFocus:true});
	amountTransferFocus = () => this.setState({amountTransferFocus:true});
	operatorSwitch = () => {
		const {user} = this.state;
		user.type = userType.OPERATOR;
		ls('user', user);
		window.location.reload(false);
	}
	showAllCouriersInWork = async () => this.showAllCouriers(true, false);
	showAllCouriers = (showallcouriers, ordersinwork) => {
		this.setState({showallcouriers,ordersinwork});
		if (showallcouriers) this.setState({loadinginner:true});
		this.setState({loadinginner:false});
	}
	courierStatGet = (orders, isactive) => orders?.filter(f => isactive ? f.status === comonStatus.ACTIVE : f.status === comonStatus.IN_ACTIVE).length;
	courierOrderShow = async (order) => {
		const products = await OrderItems.get(order.id);
		order.orderproducts = products.data;
		this.getReportOrder(order.id)
		this.setState({modalshowcourier:true,order});
	}
	showAllOrders = (showallorders) => this.setState({showallorders});
	showAllMap = (showallmap) => this.setState({showallmap});
	showCookOrders = (showcookorders) => this.setState({showcookorders});
	showToKassa = (modalshowtokassa) => this.setState({modalshowtokassa});
	showCloseKassa = (modalshowclosekassa) => this.setState({modalshowclosekassa}, () => modalshowclosekassa ? null : this.setState({dailyReport:null}));
	orderLogist = () => this.setState({modalshowpromptlogist:true});
	orderLogistHide = () => this.setState({modalshowpromptlogist:false});
	orderLogistSave = () => {
		const {user} = this.state, status = orderStatus.LOGIST_WAIT, order = {status};
		const o = this.state.order;
		Orders.update(o.id, order, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:o.status}), JSON.stringify(order), o.id, eventType.ORDER);
			const statuses = {
				orderId:o.id,
				userId:user.id,
				userName:user.name,
				status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			this.ordersUpdate();
		});
		this.setState({loading:true});
		this.modalHide();
		this.showCookOrders(false);
		alertShow('Заказ забран у поваров');
	}
	isTodayOrder = (courierId, orderId) => {
		const {couriers} = this.state;
		const courier = couriers.find(f => f.id === courierId);
		const order = courier ? courier.orders.find(f => f.orderId === orderId) : null;
		return order ? order.isdate : false;
	}
	debtGet = (courierId) => {
		const {debts,dayshift} = this.state;
		const debt = debts ? debts.filter(f => f.userId === courierId) : null;
		const amount = debt ? debt.reduce((a,b) => a + b.debt, 0) : 0;
		const amountOld = debt ? debt.filter(f => f.dayShiftId !== dayshift.id).reduce((a,b) => a + b.debt, 0) : 0;
		const amountNow = amount - amountOld;
		const isOldOrders = debt ? debt.filter(f => f.dayShiftId !== dayshift.id && !this.isTodayOrder(courierId, f.orderId)).length > 0 : false;
		return amount ? <div className={styles.moneyreport}>
				{amountNow > 0 ?
					<>
						<b>Долг: {amountNow} ₽</b>
						<div className={styles.moneyreportcurrent}>
							<button onClick={() => this.courierDebtsClose(courierId, false)}>Закрыть текущие долги</button>
						</div>
					</> : null}
				{amountOld ? <div className={styles.moneyreportold}>
					<div>Долг по старым сменам: {amountOld} ₽</div>
					{isOldOrders ? <button onClick={() => this.courierDebtsClose(courierId, true)}>Закрыть старые долги</button> : null}
				</div> : null}
			</div> : null;
	}
	courierDeptGet = (courierId, orderId) => {
		const {debts} = this.state;
		const debt = debts ? debts.find(f => f.userId === courierId && f.orderId === orderId) : null;
		return debt ? <span><b>Долг: {debt.debt}₽</b></span> : null;
	}
	courierDebtsClose = async (courierId, isold) => {
		const {debts,user,dayshift} = this.state;
		const debt = debts ? (isold ? debts.filter(f => f.userId === courierId && f.dayShiftId !== dayshift.id && !this.isTodayOrder(courierId, f.orderId)) :
				debts.filter(f => f.userId === courierId && (f.dayShiftId === dayshift.id || this.isTodayOrder(courierId, f.orderId)))) : null;
		for (const item of debt) {
			const data = {
				status:orderMoneyStatus.FINISH,
				orderAmountFinal:item.amountFull
			};
			if (isold) data.isOnline = true;
			if (data.isOnline) data.orderAmountFinal = item.amount;
			const ordercourier = {status:comonStatus.IN_ACTIVE,dateModify:dateNow()};
			CourierOrders.update(item.orderId, ordercourier);
			OrderMoneyReports.updateByOrderId(item.orderId, data);
			const order = {status:orderStatus.FINISH,dateFinish:dateNow()};
			Orders.update(item.orderId, order);
			const statuses = {
				orderId:item.orderId,
				userId:user.id,
				userName:user.name,
				status:order.status
			};
			OrderStatuses.add(statuses);
			const acc = await Accountings.byOrderIdGet(item.orderId);
			if (acc) {
				const accounting = acc.data[0];
				const data = {
					dayShiftIdFinish:dayshift.id,
					status:comonStatus.IN_ACTIVE,
					debt:0,
					description:isold?'Закрытие долгов за прошлые смены':'Закрытие текущих долгов',
					isManual:true,
					finishDate:dateNow()
				};
				Accountings.update(accounting.id, data);
			} else Logs.add(`Accounting not found by order ID: ${item.orderId}, method: courierDebtsClose`);
		}
		alertShow('Средства были учтены. Заказы завершены!');
		setTimeout(() => this.ordersUpdate(), 1000);
	}
	oldOrdersClose = () => {
		const {inworkold,user} = this.state;
		for (const item of inworkold) {
			const data = {
				status:orderMoneyStatus.FINISH,
				orderAmountFinal:item.amount
			};
			const ordercourier = {status:comonStatus.IN_ACTIVE,dateModify:dateNow()};
			CourierOrders.update(item.orderId, ordercourier);
			OrderMoneyReports.updateByOrderId(item.orderId, data);
			const order = {status:orderStatus.FINISH,dateFinish:dateNow()};
			Orders.update(item.orderId, order);
			const statuses = {
				orderId:item.orderId,
				userId:user.id,
				userName:user.name,
				status:order.status
			};
			OrderStatuses.add(statuses);
		}
		alertShow('Заказы завершены!');
		setTimeout(() => this.ordersUpdate(), 1000);
	}
	moneyTransfer = () => {
		const {amountTransfer,user,dayshift} = this.state;
		const data = {
			areaId:dayshift.areaId,
			dayShiftId:dayshift.id,
			userId:user.id,
			userName:user.name,
			amount:amountTransfer,
			type:moneyTransferType.LOGIST_CASHER
		};
		MoneyTransfers.add(data);
		this.showToKassa(false)
		this.setState({amountTransfer:''});
		alertShow('Средства переданы в кассу!');
	}
	dailyExchange = (amount) => {
		const {user,dayshift} = this.state;
		const data = {
			areaId:dayshift.areaId,
			dayShiftId:dayshift.id,
			userId:user.id,
			userName:user.name,
			amount,
			type:moneyTransferType.DAILY_CHANGE
		};
		MoneyTransfers.add(data);
	}
	moneyKassaTransfer = async () => {
		this.showCloseKassa(true);
		await this.report();
	}
	moneyKassaPrint = () => {
		const {dailyReport,activearea,dayshift} = this.state;
		const data = {
			areaId:activearea.id,
			dayShiftId:dayshift.id,
			description:`Отчет за смену ${dateGet(todayGet())}`,
			data:JSON.stringify(dailyReport),
			type:printerJobType.DAILY_REPORT,
			status:comonStatus.ACTIVE
		};
		PrinterJobs.add(data);
		alertShow('Отчет сформирован успешно!');
		this.showCloseKassa()
	}
	reportOrdersGet = async (areaId, dayShiftId) => {
		const now = dateNow();
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => (f.date === 0 || f.date < now) && this.allowStatuses.includes(f.status));
	}
	reportOrderOldGet = async () => await Orders.reports.ondatenow();
	report = async () => {
		const {activearea,dayshift} = this.state;
		const ordersOldData = await this.reportOrderOldGet();
		const ordersOld = ordersOldData.data.filter(f => f.areaId === activearea.id);
		const ordersNow = await this.reportOrdersGet(activearea.id, dayshift.id);
		const ordersAll = [...ordersNow, ...ordersOld];
		const allDelivery = ordersAll.filter(f => f.isPickup === orderPickup.DELIVERY);

		/* save logs
		const ids_old = ordersOld.map(v => v.id);
		const ids_all = ordersAll.map(v => v.id);
		const ids_devilery = allDelivery.map(v => v.id);
		Logs.add(`report date: ${new Date()}`);
		Logs.add(`report old: ${ids_old}`);
		Logs.add(`report all: ${ids_all}`);
		Logs.add(`report devilery: ${ids_devilery}`);
		*/

		const allPaid = allDelivery.filter(f => f.isPaid === orderPaid.PAID && f.isQR === 0);
		const allQR = allDelivery.filter(f => f.isQR === 1);
		const couriersDebt = await Accountings.report.inWork(activearea.id, dayshift.id);
		const terminal = await Accountings.report.finish(activearea.id, dayshift.id);
		const couriersDebtOld = await Accountings.report.inWorkOld(activearea.id, dayshift.id);
		const terminalOld = await Accountings.report.finishOld(activearea.id, dayshift.id);
		const logistClose = await Accountings.report.logist(activearea.id, dayshift.id);
		const cashin = ls('cashin');
		const amountKassa = cashin.amount;
		const kassa = await MoneyTransfers.get(activearea.id, dayshift.id, moneyTransferType.CASHIER_LOGIST);
		const kassaLogist = kassa.data.length > 0 ? kassa.data.reduce((a, b) => a + b.amount, 0) : 0;
		const dailyReport = {
			delivery: {
				count:allDelivery.length,
				amount:this.reportSumCalc(allDelivery)
			},
			paid: {
				count:allPaid.length,
				amount:this.reportSumCalc(allPaid)
			},
			qr: {
				count:allQR.length,
				amount:this.reportSumCalc(allQR)
			},
			couriersDebt: {
				count:0,
				amount:couriersDebt.data - couriersDebtOld.data
			},
			terminal: {
				count:0,
				amount:terminal.data
			},
			couriersDebtOld: {
				count:0,
				amount:couriersDebtOld.data
			},
			terminalOld: {
				count:0,
				amount:terminalOld.data
			},
			logistClose: {
				count:0,
				amount:logistClose.data
			},
			kassaLogist: {
				count:0,
				amount:kassaLogist
			},
			kassa: {
				count:0,
				amount:amountKassa
			},
			logist: {
				count:0,
				amount:0
			}
		};
		dailyReport.area = activearea.name;
		dailyReport.logist.amount = (dailyReport.delivery.amount + dailyReport.kassa.amount + dailyReport.kassaLogist.amount + dailyReport.terminalOld.amount)
							- dailyReport.paid.amount - dailyReport.qr.amount - dailyReport.terminal.amount - dailyReport.couriersDebt.amount;
		this.setState({dailyReport});
	}
	reportSumCalc = (data) => data.length > 0 ? data.reduce((a, b) => a + b.priceFull, 0) : 0;
	orderTime = (v) => {
		const dateNow = () => dateRound(new Date().getTime());
		const dateDiffInHours = (date1, date2) => Math.floor((date2 - date1) / 3600000);
		const dateDiffInMinutes = (date1, date2) => Math.floor((date2 - date1) / 60000);
		const dateRound = (time) => Math.round(time / 1000) * 1000;
		let timeRemaining = null;
		let timeElapsed = null;
		let status = null;
		let style = styles.defaultsend
		if (v.date) {
		  	const dateTo = dateRound(v.date * 1000);
		  	const dateFrom = dateRound(dateNow());
			const hoursRemaining = dateDiffInHours(dateFrom, dateTo);
			const minutesRemaining = dateDiffInMinutes(dateFrom, dateTo) % 60;
			if (dateFrom > dateTo) {
				status = 'Просрочен';
				style = styles.notsend
			} else if (hoursRemaining < 1 && minutesRemaining < 30) {
				timeRemaining = `${hoursRemaining}ч ${minutesRemaining}м`;
				style = styles.criticalsend
			} else if (hoursRemaining < 1 && minutesRemaining > 30) {
				timeRemaining = `${hoursRemaining}ч ${minutesRemaining}м`;
				style = styles.infosend
		  	} else {
				timeRemaining = `${hoursRemaining}ч ${minutesRemaining}м`;
				style = styles.defaultsend
		  	}
		} else {
		  	const dateFrom = dateRound(v.dateCreate * 1000);
		  	const dateTo = dateRound(dateNow());
		  	const hoursElapsed = dateDiffInHours(dateFrom, dateTo);
		  	const minutesElapsed = dateDiffInMinutes(dateFrom, dateTo) % 60;
	  		  	if (hoursElapsed >= 3) {
				status = 'Просрочен';
				style = styles.notsend
		  	} else if (hoursElapsed > 1) {
				status = 'Пора отправлять';
				style = styles.warnsend
			} else if (hoursElapsed === 1) {
				status = 'Пора отправлять';
				style = styles.infosend
		  	} else {
				timeElapsed = `${hoursElapsed}ч ${minutesElapsed}м`;
				style = styles.defaultsend
		  	}
		}
		return (
		  <div className={style}>
			{v.date ?
			  		(timeRemaining ? <div className={styles.time}>Осталось: {timeRemaining}</div> : <div>{status}</div>)
				:
					(timeElapsed ? <div className={styles.time}>Прошло: {timeElapsed}</div> : <div>{status}</div>)
			}
		  </div>
		);
	};
	getReportOrder = async (orderId) => {
		this.setState({loadCurrentOrder: true});
		await OrderMoneyReports.getByOrderId(orderId).then((order) => {
			Orders.get(orderId, (res) => {
				const data = {...order.data[0], order: res}
				
				this.setState({currentOrder: data, loadCurrentOrder: false})
			})
		})
	}
	handleSwitch(courierId, online) {
		const { onCourierStatusChange } = this.props;
		onCourierStatusChange(courierId, online);
	}
	courierSwitch = (courier) => {
		const handleSwitch = (courierId) => {
			this.setState(prevState => {
			  const { couriers } = prevState;
			  const updatedCouriers = couriers.map(courier => {
				if (courier.id === courierId) {
					const data = { online: !courier.online }
					Users.update(courier.id, data, () => {})
				  	return { ...courier, online: data.online }
				}
				return courier;
			  });
			  return { couriers: updatedCouriers };
			});
		  }
		return (<div className={styles.switcher}>
			<label htmlFor={`courier-switch-${courier.id}`} className={styles.switch}>
			  	<input
					type="checkbox"
					id={`courier-switch-${courier.id}`}
					checked={courier.online}
					onChange={(e) => handleSwitch(courier.id)}
			  	/>
			  	<span className={styles.slider}></span>
			</label>
			<span>{courier.online ? 'Онлайн' : 'Оффлайн'}</span>
		</div>)
	}
	checkTime = async (points) => {
		const ymaps = this.ymaps;
		if (!ymaps) {
			console.error('Yandex Maps API не доступен.');
			return;
		}
		if (points.length < 2) {
			console.error('Для расчета времени необходимо не менее 2-ух точек');
			return;
		}
		const route = ymaps.route(points.map((point) => point.lat + ',' + point.lon));
		try {
			const res = await route;
			const distance = res.getHumanLength().replace('&#160;', ' ');
			const duration = res.getHumanTime().replace('&#160;', ' ');
			return { distance, duration };
		}
		catch (error) {
			console.error('Ошибка при вычислении маршрута:', error);
			throw error;
		}
	}
	forecastGet = (ride) => {
		const time = (ride.jamsTime.plain + ride.jamsTime.plain/10)/60;
		return Math.round(time);
	}
	forecastDelayGet = (ride) => {
		const timeJam = (ride.jamsTime.plain + ride.jamsTime.plain/10)/60;
		const time = (ride.time.plain + ride.time.plain/10)/60;
		const delta = 1;
		const delay = Math.abs(Math.round((timeJam - time) * delta));
		return delay < 5 ? 5 : delay;
	}
	forecastDateGet = (ride) => {
		const now = new Date();
		const time = Math.round((ride.jamsTime.plain + (ride.jamsTime.plain/10))/60);
		const delay = this.forecastDelayGet(ride);
		now.setMinutes(now.getMinutes() + time + delay);
		return Math.round(now.getTime() / 1000);
	}
	parseMeasurementString = (str) => {
		if (!str) return { value: null, unit: null }
		const regex = /(\d+\.\d+|\d+)\s*([a-zA-Zа-яА-Я]+)/;
    	const match = str.match(regex);
    	if (match) {
			const value = parseFloat(match[1]);
			const unit = match[2].toLowerCase();
			if (unit === 'см') return { value, unit: 'см' };
			else if (unit === 'км') return { value, unit: 'км' };
			else if (unit === 'м') return { value, unit: 'м' };
			else return { value, unit: 'неизвестно' };
		}
		return { value: null, unit: null };
	}
	courierInfoGet = (courier) => {
		const out = [];
		if (courier.orders) {
			const activeOrders = courier.orders.filter(f => f.status === comonStatus.ACTIVE);
			const finishOrders = courier.orders.filter(f => f.status === comonStatus.IN_ACTIVE);
			console.log('courier info', courier, activeOrders.length, finishOrders.length)
			const laststatus = this.state.courierstatuses.find(f => f.userId === courier.id);
			if (laststatus) {
				if (laststatus.status === courierStatus.ON_BASE) out.push('На складе');
				else if (laststatus.status === courierStatus.ON_BACK) out.push('Едет на склад');
				else if (laststatus.status !== courierStatus.UNKNOWN) {
					const now = dateNow();
					if ((now - tsDay) > laststatus.dateCreate) {
						out.push('Не выполняет заказы');
						out.push(<>Последниый заказ <b>#{laststatus.orderId}</b></>);
					}
					else out.push(<>На заказе <b>#{laststatus.orderId}</b></>);
				}
			}
			if (activeOrders.length) {
				const time = courier.rideData && courier.rideData.duration ? courier.rideData?.duration?.replace('&#160;', ' ') : null;
				if (time) out.push(`Вернется через: ~${time}`);
				out.push(`В работе: ${activeOrders.length}`);
			}
			if (finishOrders.length) out.push(`Выполнено: ${finishOrders.length}`);
		}
		return out.length ? <div className={styles.courierinfo}>{out.map((v, i) => <div key={i}>{v}</div>)}</div> : null;
	}
	courierInfoOrderShow = async (courier) => {
		const laststatus = this.state.courierstatuses.find(f => f.userId === courier.id);
		if (laststatus) {
			if (laststatus.status === courierStatus.ON_ROUTE || laststatus.status === courierStatus.ON_ADDRESS) {
				const o = await Orders.getAsync(laststatus.orderId);
				if (o && o.data) {
					const order = o.data;
					const products = await OrderItems.get(order.id);
					order.orderproducts = products.data;
					this.setState({modalshowcourier:true,order});
				}
			}
		}
	}
	getStatusRide = (courier) => {
		if (courier.orders) {
			const hasActiveOrders = courier.orders.find((order) => order.order.status !== orderStatus.FINISH);
			const laststatus = this.state.courierstatuses.find(f => f.userId === courier.id);
			if (laststatus) {
				let distanceStr = null;
				const distance = this.parseMeasurementString(courier.rideData ? courier.rideData.distance : '');
				if (laststatus.status === courierStatus.ON_BASE) distanceStr = 'Сейчас на складе';
				if (laststatus.status === courierStatus.ON_BACK) {
					distanceStr = 'Едет на склад';
					if (distance.unit === 'см' || distance.unit === 'м') {
						if(distance.value < 200) distanceStr = 'Сейчас на складе (не отметился)';
						else distanceStr = `${distanceStr}. ${distance.value} ${distance.unit === 'см' ? 'м' : distance.unit} от склада`;
					}
				}
				distanceStr = distanceStr === null ? null : distanceStr.replace('&#160;', ' ');
				if (!courier.orders.length)
					return `Не выполняет заказы${distanceStr ? ` (${distanceStr})` : ''}`;
				if (distanceStr !== null) return distanceStr;
				if (laststatus.status !== courierStatus.UNKNOWN) {
					const time = courier.rideData && courier.rideData.duration ? courier.rideData?.duration?.replace('&#160;', ' ') : null;
					if (hasActiveOrders) return `Есть заказы в работе${time ? `. Вернется через: ~${time}` : ''}`;
					else return `Нет активных заказов${time ? `. Вернется через: ${time}` : ''}`;
				}
			} else console.log('courier status not found', courier.id);

			// !!! временно закомментил подсчет, пока толька инфа из статуса - едет на базу, на базе, на заказе
			/*
			const distance = this.parseMeasurementString(courier.rideData ? courier.rideData.distance : '');
			let distanceStr = '';
			if (distance.unit === 'см' || distance.unit === 'м') {
				if(distance.value < 200) {
					distanceStr = `Сейчас на складе`;
				} else {
					distanceStr = `${distance.value} ${distance.unit === 'см' ? 'м' : distance.unit} от склада`;
				}
			} else {
				distanceStr = `${distance.value} ${distance.unit === 'см' ? 'м' : distance.unit} от склада`;
			}
			distanceStr = distanceStr.replace('&#160;', ' ');
			if (!courier.orders.length) {
				return `Не выполняет заказы (${distanceStr})`;
			}
			if (courier.rideData) {
				if (hasActiveOrders) {
					return 'Есть заказы в работе. Вернется через: ~' + courier.rideData.duration?.replace('&#160;', ' ');
				} else {
					return 'Нет активных заказов. Вернется через: ' + courier.rideData.duration?.replace('&#160;', ' ') + ` (${distanceStr})`;
				}
			} else return hasActiveOrders ? 'Есть заказы в работе' : 'Нет активных заказов';
			*/
		}
	}
	cashInShow = (dayshift) => {
		const cashin = ls('cashin');
		if (cashin) {
			if (cashin.areaId === dayshift.areaId && cashin.dayShiftId === dayshift.id) return;
		}
		this.setState({modalshowcashin:true});
	}
	cashPaperSet = (cashPaper) => this.setState({cashPaper});
	cashCoinSet = (cashCoin) => this.setState({cashCoin});
	cashOpen = () => {
		const {dayshift,cashPaper,cashCoin} = this.state;
		const amount = parseFloat(cashPaper) + parseFloat(cashCoin);
		ls('cashin', {areaId:dayshift.areaId,dayShiftId:dayshift.id,isopen:true,amount});
		this.setState({modalshowcashin:false});
		this.dailyExchange(amount);
	}
	render() {
		if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
		if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
		this.state.orders.forEach((v) => v.needsend = this.orderTime(v));
		return <div className={styles.container}>
			{this.state.isswitch ? <div className={styles.switchcontainer}>
				<button onClick={() => this.operatorSwitch()}>
					<img src={require('../../../Images/icons/operator.svg')} alt="Оператор" />
					Оператор
				</button>
			</div> : null}
			<div className={styles.areaactive}>
				<button>
					<img src={require('../../../Images/icons/marker_black.svg')} alt="Точка" />
					{this.state.activearea.name}
				</button>
			</div>
			{this.state.showallcouriers ?
				(
					this.state.loadinginner ? <div className={styles.main}><Loading className={styles.loading} /></div> :
						<div className={styles.mainfull}>
							<div className={styles.header}>
								<h4>
									{this.state.ordersinwork ? 'Заказы в работе' : 'Выполненные заказы'}
									{this.state.ordersinwork ? <button className={styles.statbutton} onClick={() => this.modalStatShow()}>Статистика/Прогноз</button> : null}
								</h4>
								<Close close={() => this.showAllCouriers(false, false)} />
							</div>
							<Scroller className={styles.inner}>
								{this.state.couriers.map((v,i) => <div key={i} className={styles.row}>
									<div className={styles.name}>
										<b>{v.name}</b>
										{phoneFormatter(v.phone)}
										{this.state.ordersinwork ? null : this.debtGet(v.id)}
										{this.courierSwitch(v)}
									</div>
									<div className={styles.stat}>
										В работе
										<span>{this.courierStatGet(v.orders, true)}</span>
									</div>
									<div className={cx(styles.stat)}>
										Выполнено
										<span>{this.courierStatGet(v.orders, false)}</span>
									</div>
									<div className={cx(styles.stat,styles.statlast)} style={{width:'200px'}}>{this.getStatusRide(v)}</div>
									<div className={styles.orderslist}>
										{v.orders?.filter(f => f.status === (this.state.ordersinwork ? comonStatus.ACTIVE : comonStatus.IN_ACTIVE)).map((o,j) => <div key={j} className={styles.orderblock} onClick={() => this.courierOrderShow(o.order)}>
											Заказ №{o.orderId}
											{this.courierDeptGet(v.id, o.order.id)}
											<span>{o.order.address}</span>
										</div>)}
									</div>
								</div>)}
							</Scroller>
							<Modal visible={this.state.modalshowstat} width={'900'} height={'600'} effect={'fadeInUp'}>
								<div className={styles.modalcontainer}>
									<div className={styles.header}>
										<h4>Статистика и прогноз по курьерам</h4>
										<span>на {dateGet(dateNow(), {yearHide:true,showMonthFullName:true,showTime:true})}</span>
									</div>
									<Scroller className={styles.productslist}>
										<Couriers couriers={this.state.couriers ? this.state.couriers : []} courierOrderShow={this.courierOrderShow} getStatusRide={this.getStatusRide} />
									</Scroller>
								</div>
								<Close close={this.modalStatHide.bind(this)} />
							</Modal>
							<div className={styles.mainfull}>
								<div className={styles.mapContainer}>
									<YMaps query={{ apikey: MAP.keyYandex }}>
										<Map
											instanceRef={(map) => this.map = map}
											onLoad={(ymaps) => this.onOtherMapLoad(ymaps)}
											modules={['route']}
											state={{ center: MAP.defaultCoordinates, zoom: 12 }}
											width={'1px'} height={'1px'}
										>
											<ZoomControl />
										</Map>
									</YMaps>
								</div>
							</div>
						</div>
				)
			:
				this.state.showallorders ?
					(
						<div className={styles.mainfull}>
							<div className={styles.header}>
								<h4>Все заказы за смену</h4>
								<Close close={() => this.showAllOrders(false)} />
							</div>
							<Scroller className={styles.inner}>
								{this.state.ordersall.length === 0 ?
									<div className={styles.empty}>
										<b>Нет заказов в работе</b>
										<p>Здесь отображаются заказы, которые преданы курьеру</p>
									</div>
								:
									this.state.ordersall.map((v,i) => <div key={i} className={styles.row}>
										<div className={styles.numdate}>
											<span>{i+1}. <b>№{v.id}</b></span>
											{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}
										</div>
										<div className={styles.status}>
											{orderStatusName[v.status]}
											{v.status === orderStatus.FINISH ? <div>{dateGet(v.dateFinish, {yearHide:true,showMonthFullName:true,showTime:true})}</div> : null}
										</div>
										<div className={styles.couriername}>
											{v.isPickup ?
												<b>Самовывоз</b>
											:
												<>
													<b>{v.courierName}</b>
													{phoneFormatter(v.courierPhone)}
												</>
											}
										</div>
										<button className={styles.infobutton} onClick={() => this.courierOrderShow(v)}>Подробности</button>
									</div>)}
							</Scroller>
						</div>
					)
				:
					this.state.showallmap ?
						<div className={styles.mainfull}>
							<div className={styles.map}>
								<YMaps query={{apikey:MAP.keyYandex}}>
									<Map
										instanceRef={(map) => this.map = map}
										onLoad={(ymaps) => this.onMapLoad(ymaps)}
										state={{center:MAP.defaultCoordinates,zoom:12}}
										width={'auto'} height={'100%'}>
											<ZoomControl />
											{this.state.orders.map((v,i) => <Placemark
												key={i}
												geometry={[v.latitude,v.longitude]}
												properties={{balloonContent:`<div><b>Заказ №${v.id}</b><br>${v.address}</div>`,hintContent:v.address}}
												modules={['geoObject.addon.hint','geoObject.addon.balloon']} />
											)}
										</Map>
								</YMaps>
								<Close close={() => this.showAllMap(false)} />
							</div>
						</div>
					:
						this.state.showcookorders ?
							<div className={styles.mainfull}>
								<div className={styles.header}>
									<h4>Заказы у поваров</h4>
									<Close close={() => this.showCookOrders(false)} />
								</div>
								<Scroller className={styles.inner}>
									{this.state.orderscook.length === 0 ?
										<div className={styles.empty}>
											<b>Нет заказов у поваров</b>
											<p>Здесь отображаются заказы, которые преданы поварам</p>
										</div>
									:
										this.state.orderscook.map((v,i) => <div key={i} className={styles.row}>
											<div className={styles.numdate}>
												<span>{i+1}. <b>№{v.id}</b></span>
												{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}
												{v.date ? <div>на дату: <b>{dateGet(v.date, {yearHide:true,showMonthFullName:true,showTime:true})}</b></div> : null}
											</div>
											<div className={styles.status}>
												{orderStatusName[v.status]}
												{v.status === orderStatus.FINISH ? <div>{dateGet(v.dateFinish, {yearHide:true,showMonthFullName:true,showTime:true})}</div> : null}
											</div>
											<div className={styles.couriername}>
												{v.isPickup ?
													<b>Самовывоз</b>
												:
													<>
														<b>{v.courierName}</b>
														{phoneFormatter(v.courierPhone)}
													</>
												}
											</div>
											<button className={styles.infobutton} onClick={() => this.courierOrderShow(v)}>Подробности</button>
										</div>)}
								</Scroller>
							</div>
						:
							(
								<div className={styles.main}>
									<div className={styles.column}>
										<div className={styles.columntop}>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.couriers)} onClick={() => this.showAllCouriers(true, true)}>
													<div>
														<img src={require('../../../Images/icons/user_white.svg')} alt="" />
														В работе
													</div>
												</div>
											</div>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.couriersdone)} onClick={() => this.showAllCouriersInWork()}>
													<div>
														<img src={require('../../../Images/icons/user_white.svg')} alt="" />
														Выполнено
													</div>
												</div>
											</div>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.orders)} onClick={() => this.showAllOrders(true)}>
													<div>
														<img src={require('../../../Images/icons/order_white.svg')} alt="" />
														Заказы
													</div>
												</div>
											</div>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.map)} onClick={() => this.showAllMap(true)}>
													<div>
														<img src={require('../../../Images/icons/map_white.svg')} alt="" />
														На карте
													</div>
												</div>
											</div>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.cook)} onClick={() => this.showCookOrders(true)}>
													<div>
														<img src={require('../../../Images/icons/cook_white.svg')} alt="" />
														У повара
													</div>
												</div>
											</div>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.orders)} onClick={() => this.showToKassa(true)}>
													<div>
														<img src={require('../../../Images/icons/money_transfer_white.svg')} alt="" />
														В кассу
													</div>
												</div>
											</div>
											<div className={styles.columnblock}>
												<div className={cx(styles.button,styles.close)} onClick={() => this.moneyKassaTransfer()}>
													<div>
														<img src={require('../../../Images/icons/kassa_white.svg')} alt="" />
														Сверка
													</div>
												</div>
											</div>
										</div>
										{this.state.orders.length === 0 ? <div className={styles.empty}>Пусто</div> :
											<div className={styles.maininner}>
												<div className={styles.mainorders}>
													<Scroller className={styles.container}>
														{this.state.orders.map((v,i) => <div key={i} className={cx(styles.item,this.additionalStatus(v))} onClick={() => this.orderSelect(v, false)}>
															<div className={styles.inneritem}>
																<div className={cx(styles.number,styles.oneline)}>
																	№{v.id}
																	{v.needsend}
																</div>
																<div className={cx(styles.block,styles.oneline)}>
																	<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
																	{v.date ? <div className={styles.ondate}>заказ на <br/>{dateGet(v.date, {yearHide:true,showMonthFullName:true,showTime:true})}</div> : null}
																</div>
																<div className={styles.block}>
																	<div><b>{v.isPickup ? 'Самовывоз' : (v.address || 'В зале')}</b></div>
																	<div className={styles.amount}>{moneyFormat(v.priceFull, false)} ₽</div>
																</div>
																<div className={styles.products}>
																	{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
																</div>
															</div>
														</div>)}
													</Scroller>
												</div>
												<div className={styles.maincouriers}>
													<Scroller className={styles.maincourierslist}>
														<ul>
															{this.state.couriers.map((v,i) => <li key={i} onClick={() => this.courierInfoOrderShow(v)}>
																<div>
																	<div className={styles.oneline}>
																		<div className={cx(styles.onlinestatus,v.online?styles.online:styles.offline)}></div>
																		<div className={styles.name}>{v.name}</div>
																	</div>
																	<div className={styles.phone}>{phoneFormatter(v.phone)}</div>
																	{this.courierInfoGet(v)}
																</div>
															</li>)}
														</ul>
													</Scroller>
												</div>
											</div>}
									</div>
									<div className={cx(styles.column,styles.columnsingle)}>
										<div className={styles.header}>
											Заказы у курьеров
											<img src={require('../../../Images/icons/statuses/clock.svg')} alt="" />
										</div>
										{this.state.inworkold.length !== 0 ? <div className={styles.oldorders}>
												<button onClick={() => this.oldOrdersClose()}>Закрыть старые заказы</button>
											</div> : null}
										{this.state.inwork.length === 0 ? <div className={styles.empty}>Пусто</div> :
											<Scroller className={styles.container}>
												{this.state.inwork.map((v,i) => <div key={i} className={cx(styles.item,styles.itemlite)} onClick={() => this.orderFinishSelect(v)}>
														<div className={styles.inneritem}>
															<div className={styles.block}>
																<div className={styles.number}>№{v.order.id}</div>
																<div className={styles.amount}>{moneyFormat(v.order.priceFull, false)} ₽</div>
															</div>
															<div className={styles.block}>
																<div>{v.order.address}</div>
															</div>
															<div className={styles.block}>
																<div><b>{v.courierName}</b></div>
															</div>
														</div>
												</div>)}
											</Scroller>}
									</div>
								</div>
							)
			}
			{this.state.modalshowcalc && <Modal visible={this.state.modalshowcalc} width={'330'} height={this.state.report.order.type === orderType.CLIENT ? '780' : '740'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Отчет о принятии средств</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div className={styles.cancelinput}>
							<p>Сумма заказа: <b>{moneyFormat(this.state.report.order.priceFull, false)} ₽</b></p>
							<div className={styles.reportorderinfo}>
								Заказ №: <b>{this.state.report.order.id}</b><br/>
								Курьер: <b>{this.state.report.courierName}</b><br/>
								Адрес: {this.state.report.order.address}
							</div>
							<input type="text" placeholder="Сумма" maxLength={7} readOnly={true} value={this.state.amount} onFocus={this.amountFocus} className={cx(styles.input,this.state.amountFocus?styles.focus:null)} />
							<label>
								<input type="checkbox" defaultChecked={this.state.isOnline} onChange={this.handleOnlineForm} />
								Оплата через терминал
							</label>
							{this.state.report.order.type === orderType.CLIENT ?
								<label>
									Интернет-заказ{this.state.report.order.isPaid ? '. Оплачен онлайн' : null}
								</label> : null}
						</div>
						<div className={styles.keypad}>
							<div className={styles.num} onClick={() => this.numberSet(7)}>7</div>
							<div className={styles.num} onClick={() => this.numberSet(8)}>8</div>
							<div className={styles.num} onClick={() => this.numberSet(9)}>9</div>
							<div className={styles.num} onClick={() => this.numberSet(4)}>4</div>
							<div className={styles.num} onClick={() => this.numberSet(5)}>5</div>
							<div className={styles.num} onClick={() => this.numberSet(6)}>6</div>
							<div className={styles.num} onClick={() => this.numberSet(1)}>1</div>
							<div className={styles.num} onClick={() => this.numberSet(2)}>2</div>
							<div className={styles.num} onClick={() => this.numberSet(3)}>3</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberSet(0)}>0</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberSet('.')}>.</div>
							<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.numberSet()}>Удл</div>
						</div>
						<div>
							<button className={styles.button} onClick={() => this.orderFinishSave()}>Внести</button>
						</div>
						<div className={styles.control}>
							<button className={cx(styles.button,styles.change)} onClick={() => this.courierChange()}>Сменить</button>
							<button className={cx(styles.button,styles.order)} onClick={() => this.orderFinishOrderShow()}>Заказ</button>
						</div>
					</div>
				</div>
				<Close close={this.modalReportHide.bind(this)} />
			</Modal>}
			{this.state.order ?
				((this.state.order.status === orderStatus.LOGIST_WAIT || this.state.order.status === orderStatus.LOGIST_ACCEPTED) &&
					(this.state.order.type === orderType.OPERATOR_HALL || this.state.order.isPickup)) ?
							<Modal visible={this.state.modalshow} width={'900'} height={'600'} effect={'fadeInUp'}>
								<div className={styles.modalcontainer}>
									<div className={styles.header}>
										<h4>Состав заказа №{this.state.order.id}</h4>
										<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
									</div>
									<Scroller className={styles.productslist}
										customControls={<div className={styles.donefinish}>
											<button onClick={() => this.orderFinish()}><img src={require('../../../Images/icons/mark.svg')} alt="Передал клиенту" />Передал клиенту</button>
										</div>}>
										<OrderInfo data={this.state.order} orderproducts={this.state.order.orderproducts} areas={this.state.areas} />
									</Scroller>
								</div>
								<Close close={this.modalHide.bind(this)} />
							</Modal>
						:
							<Modal visible={this.state.modalshow} width={'900'} height={'600'} effect={'fadeInUp'}>
								<div className={styles.modalcontainer}>
									<div className={styles.mapcontainerorder}>
										<div className={styles.map}>
											<YMaps query={{apikey:MAP.keyYandex}}>
												<Map
													instanceRef={(map) => this.map = map}
													onLoad={(ymaps) => this.onMapLoad(ymaps)}
													modules={['route']}
													state={{center:MAP.defaultCoordinates,zoom:16}}
													width={'auto'} height={'100%'}>
														<ZoomControl />
												</Map>
											</YMaps>
										</div>
										<div className={styles.courierslist}>
											<div className={styles.header}>
												<h4>Заказ №{this.state.order.id}</h4>
												<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
												<button className={styles.orderinfo} onClick={() => this.orderInfoShow()}>Информация по заказу</button>
											</div>
											<div className={styles.rideinfocontainer}>
												{this.state.ride ?
													<>
														<div className={styles.row}>
															<div className={styles.label}>Время в пути</div>
															<div className={styles.value}>{this.state.ride.time.human}</div>
														</div>
														<div className={styles.row}>
															<div className={styles.label}>Время с учетом пробок</div>
															<div className={styles.value}>{this.state.ride.jamsTime.human}</div>
														</div>
														<div className={styles.row}>
															<div className={styles.label}>Расстояние</div>
															<div className={styles.value}>{this.state.ride.length.human}</div>
														</div>
													</>
													: <div className={styles.empty}>Нет информации по маршруту</div>
												}
											</div>
											<Scroller className={cx(styles.courierslistlist,this.state.ride?null:styles.courierslistlistempty)}>
												<ul>
													{this.state.couriers.map((v,i) => <li key={i} onClick={() => this.courierSelect(v)} className={this.state.courier && this.state.courier.userId === v.id?styles.selected:null}>
														<div>
															<div className={styles.oneline}>
																<div className={cx(styles.onlinestatus,v.online?styles.online:styles.offline)}></div>
																<div className={styles.name}>{v.name}</div>
															</div>
															<div className={styles.phone}>{phoneFormatter(v.phone)}</div>
														</div>
														{this.state.courier && this.state.courier.userId === v.id ? <button onClick={() => this.orderDone()}>Назначить</button> : null}
													</li>)}
												</ul>
											</Scroller>
										</div>
										<div className={styles.orderaddress}>
											{this.state.order.address}
											<img src={require('../../../Images/icons/reload.svg')} className={styles.update} alt="Обновить" onClick={() => this.courierRoutUpdate()} />
										</div>
										{this.state.ride ?
											<div className={styles.routepanel}>
												<div className={styles.row}>
													<div className={styles.label}>У клиента через</div>
													<div className={styles.value}>{this.state.ride.forecastTime} мин</div>
												</div>
												<div className={styles.row}>
													<div className={styles.label}>Вероятная задержка</div>
													<div className={styles.value}>{this.state.ride.forecastDelay} мин</div>
												</div>
												<div className={styles.row}>
													<div className={styles.label}>Время прибытия</div>
													<div className={styles.value}>{dateGet(this.state.ride.forecastDate, {onlyTime:true})}</div>
												</div>
											</div> : null}
									</div>
								</div>
								<Close close={this.modalHide.bind(this)} />
							</Modal>
			: null}
			{this.state.order ?
				<Modal visible={this.state.modalshoworderinfo} width={'900'} height={'600'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<div className={styles.header}>
							<h4>Состав заказа №{this.state.order.id}</h4>
							<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
						</div>
						<Scroller className={styles.productslist}>
							<OrderInfo data={this.state.order} orderproducts={this.state.order.orderproducts} areas={this.state.areas} />
						</Scroller>
					</div>
					<Close close={this.modalOrderInfoHide.bind(this)} />
				</Modal> : null}
			{this.state.order && <Modal visible={this.state.modalshowchange} width={'900'} height={'600'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Состав заказа №{this.state.order.id}</h4>
						<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
						{this.state.order.type === orderType.OPERATOR_HALL || this.state.order.isPickup ? null :
							<div className={styles.mapbutton} onClick={() => this.mapShow()}>
								<img src={require('../../../Images/icons/map.svg')} alt="Карта" />
								Маршрут доставки
							</div>}
					</div>
					<Scroller className={cx(styles.productslist,this.state.order.status===orderStatus.LOGIST_DONE?styles.nobutton:null)}>
						<OrderInfo data={this.state.order} orderproducts={this.state.order.orderproducts} areas={this.state.areas} />
					</Scroller>
					{this.state.iscourierchange ?
						<div className={styles.done}>
							<div className={styles.doneinner}>
								<select onChange={this.handleSelect} defaultValue={this.state.order.courierId}>
									<option value="0">Выберите курьера</option>
									{this.state.couriers.map((v,i) => <option key={i} phone={v.phone} value={v.id}>{v.name}</option>)}
								</select>
								<button onClick={() => this.orderDone()}><img src={require('../../../Images/icons/mark.svg')} alt="Назначить курьера" />Назначить</button>
							</div>
						</div> : null}
				</div>
				<Close close={this.modalChangeHide.bind(this)} />
			</Modal>}
			{this.state.modalshowcourier && <Modal visible={this.state.modalshowcourier} width={'900'} height={'600'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Состав заказа №{this.state.order.id}<span className={cx(styles.status,this.state.order.status===orderStatus.COURIER_DONE?styles.statusdone:styles.statuswork)}>{orderStatusName[this.state.order.status]}</span></h4>
						<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
						{this.state.order.type === orderType.OPERATOR_HALL || this.state.order.isPickup || this.state.order.status === orderStatus.FINISH ? null :
							<div className={styles.mapbutton} onClick={() => this.courierMapShow()}>
								<img src={require('../../../Images/icons/map.svg')} alt="Карта" />
								Курьер на карте
							</div>}
					</div>
					<Scroller className={cx(styles.productslist,styles.nobutton)}
						customControls={this.cookStatuses.includes(this.state.order.status) ?
							<button className={styles.controlbutton} onClick={() => this.orderLogist()}>
								<img src={require('../../../Images/icons/mark.svg')} alt="Принять заказ" />
								Принять заказ
							</button> : !this.state.loadCurrentOrder && (<button className={styles.controlbutton} onClick={() => this.orderFinishSelect(this.state.currentOrder)}>
								<img src={require('../../../Images/icons/mark.svg')} alt="Редактировать сумму" />
								Редактировать внесенную сумму
							</button>)}>
						<OrderInfo data={this.state.order} orderproducts={this.state.order.orderproducts} areas={this.state.areas} />
					</Scroller>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowcouriermap && <Modal visible={this.state.modalshowcouriermap} width={'900'} height={'600'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Маршрут</h4>
					</div>
					<div className={styles.mapcontainer}>
						<div className={styles.map}>
							<YMaps query={{apikey:MAP.keyYandex}}>
								<Map
									instanceRef={(map) => this.map = map}
									onLoad={(ymaps) => this.onMapLoad(ymaps)}
									modules={['route']}
									state={{center:MAP.defaultCoordinates,zoom:16}}
									width={'auto'} height={'100%'}>
										<ZoomControl />
									</Map>
							</YMaps>
						</div>
						<div className={cx(styles.orderaddress,styles.orderaddressstatic)}>
							{this.state.order.address}
							<img src={require('../../../Images/icons/reload.svg')} className={styles.update} alt="Обновить" onClick={() => this.courierRoutUpdate()} />
						</div>
						{this.state.ridecurrent ?
							<div className={cx(styles.routepanel,styles.routepanelfull)}>
								<div className={styles.row}>
									<div className={styles.label}>Расчетное время прибытия</div>
									<div className={styles.value}>{this.state.ridecurrent.dateCalc ? dateGet(this.state.ridecurrent.dateCalc, {onlyTime:true}) : '—'}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.label}>Фактическое время прибытия</div>
									<div className={styles.value}>{this.state.ridecurrent.dateCurrent ? dateGet(this.state.ridecurrent.dateCurrent, {onlyTime:true}) : '—'}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.label}>Опоздание на</div>
									<div className={styles.value}>{this.state.ridecurrent.delayHuman ? this.state.ridecurrent.delayHuman : '—'}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.label}>Осталось ехать</div>
									<div className={styles.value}>{this.state.ridecurrent.time ? `${this.state.ridecurrent.time} мин` : '—'}</div>
								</div>
							</div> : null}
					</div>
				</div>
				<Close close={this.modalMapHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpromptlogist && <Modal visible={this.state.modalshowpromptlogist} width={'600'} height={'160'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Забрать заказ у поваров?</h4>
					</div>
					<div className={styles.promptcontaineralert}>
						<div>
							<button className={styles.button} onClick={() => this.orderLogistSave()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderLogistHide()}>Нет</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowtokassa && <Modal visible={this.state.modalshowtokassa} width={'330'} height={'500'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Передача средств в кассу</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div className={styles.cancelinput}>
							<input type="text" placeholder="Сумма" maxLength={7} readOnly={true} value={this.state.amountTransfer} onFocus={this.amountTransferFocus} autoFocus={true} className={cx(styles.input,this.state.amountTransferFocus?styles.focus:null)} />
						</div>
						<div className={styles.keypad}>
							<div className={styles.num} onClick={() => this.numberTransferSet(7)}>7</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(8)}>8</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(9)}>9</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(4)}>4</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(5)}>5</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(6)}>6</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(1)}>1</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(2)}>2</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(3)}>3</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberTransferSet(0)}>0</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberTransferSet('.')}>.</div>
							<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.numberTransferSet()}>Удл</div>
						</div>
						<div>
							<button className={styles.button} onClick={() => this.moneyTransfer()}>Передать</button>
						</div>
					</div>
				</div>
				<Close close={this.showToKassa.bind(this)} />
			</Modal>}
			{this.state.modalshowclosekassa && <Modal visible={this.state.modalshowclosekassa} width={'420'} height={'630'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Сверка</h4>
						<span>{dateGet(dateNow(),{showTime:true})}</span>
					</div>
					{this.state.dailyReport === null ? <Loading className={styles.loading} /> :
						<div className={styles.promptcontainerblock}>
							<div className={cx(styles.promptcontainer,styles.promptcontainercash)}>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Все доставки</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.delivery.amount, false)} ₽<span>{this.state.dailyReport.delivery.count} шт.</span></div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Оплачены</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.paid.amount, false)} ₽<span>{this.state.dailyReport.paid.count} шт.</span></div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>QR</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.qr.amount, false)} ₽<span>{this.state.dailyReport.qr.count} шт.</span></div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Наличные в терминале</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.terminal.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Текущие долги</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.couriersDebt.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Наличные в терминале за другую смену</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.terminalOld.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Прошлые долги</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.couriersDebtOld.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Ручное закрытие долгов </div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.logistClose.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow)}>
									<div className={styles.titlereport}>Наличные для размена</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.kassa.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow,styles.reportrowlast)}>
									<div className={styles.titlereport}>Наличные от кассира</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.kassaLogist.amount, false)} ₽</div>
								</div>
								<div className={cx(styles.onelinewide,styles.reportrow,styles.reportrowtotal)}>
									<div className={styles.titlereport}>ИТОГО</div>
									<div className={styles.moneyreport}>{moneyFormat(this.state.dailyReport.logist.amount, false)} ₽</div>
								</div>
								<div className={styles.control}>
									<button className={cx(styles.button,styles.buttonwide)} onClick={() => this.moneyKassaPrint()}>Печать</button>
								</div>
							</div>
						</div>
					}
				</div>
				<Close close={this.showCloseKassa.bind(this)} />
			</Modal>}
			{this.state.modalshowcashin && <Modal visible={this.state.modalshowcashin} width={'460'} height={'450'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Выберите размен на день</h4>
					</div>
					<div className={cx(styles.promptcontainer,styles.promptcontainercash)}>
						<div className={styles.cashpapercoin}>
							<div className={styles.title}>Купюры</div>
							<div className={styles.cancelinput}>
								<input type="text" readOnly={true} value={`${this.state.cashPaper} ₽`} className={cx(styles.input,styles.focus)} />
							</div>
							<ul>{[1000,2000,3000,4000,5000].map((v,i) => <li key={i} onClick={() => this.cashPaperSet(v)} className={v===this.state.cashPaper?styles.selected:null}>{v} ₽</li>)}</ul>
							<div className={styles.title}>Монеты</div>
							<div className={styles.cancelinput}>
								<input type="text" readOnly={true} value={`${this.state.cashCoin} ₽`} className={cx(styles.input,styles.focus)} />
							</div>
							<ul>{[100,300,500,700,1000].map((v,i) => <li key={i} onClick={() => this.cashCoinSet(v)} className={v===this.state.cashCoin?styles.selected:null}>{v} ₽</li>)}</ul>
						</div>
						<div className={styles.control}>
							<button className={cx(styles.button,styles.buttonwide)} onClick={() => this.cashOpen()}>Приступить к работе</button>
						</div>
					</div>
				</div>
			</Modal>}
			<Alert />
		</div>
	}
}

export default withRouter(DashboardLogist);