import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import Loading from '../../Loading';
import Error from '../../Error';
import Close from '../../Close';
import NotFound from '../../NotFound';
import Alert,{alertShow} from '../../Alert';
import Scroller from '../../Scroller';
import Keyboard from '../../Keyboard';
import OrderInfo from '../../OrderInfo';

// models
import {Areas,Clients,Orders,Events,OrderStatuses,Settings,Maps,ClientAddresses,Logs,OrderItems,Products,Discounts,DayShifts,DeliveryAreas,Sections,PromoCodes,PromoCodeClients,Transactions,OrderPrints,Stores,CashierMoneyReports,DayShiftCashes,DayShiftCashReports,MoneyTransfers,Accountings,Refusers,PrinterJobs,DayShiftReports} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateGet,phoneFormatter,phoneNormalize,dateSegmentsGet,moneyFormat,dateNow,todayGet,empty,quantityFormat,base64} from '../../../Globals/Utils';
import Printer from '../../../Globals/Printer';
import errorsParse from '../../../Globals/Errors';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {PIN_CODE,MAP,orderStatus,orderStatusName,comonStatus,eventType,errorType,clientStatus,measureTypeName,orderType,timers,orderPaid,orderPaymentType,transactionPaymentType,orderTransactionPaymentType,transactionStatus,targetType,userType,orderPickup,SITE,requestType,requestStatus,moneyTransferType,dayShiftCashType,printerJobType} from '../../../Globals/Constants';

// styles
import styles from './DashboardOperator.module.css';
const cx = classNames.bind(styles);

class DashboardOperator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			inn:null,
			store:null,
			requests:null,
			requestids:[],
			requestrequestids:[],
			isrequestsend:false,
			orders:null,
			ordersdate:[],
			orderscancel:[],
			areas:[],
			dayshift:null,
			data:null,
			orderproducts:[],
			orderssearch:[],
			orderid:0,
			activearea:null,
			noCook:false,
			noOperator:false,
			noCollector:false,
			noLogist:false,
			addresses:null,
			addressfull:null,
			addressClient:null,
			clients:[],
			sections:null,
			products:null,
			discounts:null,
			orderitems:[],
			sectionId:0,
			sectionLink:null,
			sectionName:null,
			categoryId:0,
			categoryName:null,
			categories:[],
			paymentType:orderPaymentType.CASH,
			price:0,
			priceDiscount:0,
			discountId:0,
			discountName:'',
			discount:0,
			discountPercent:0,
			manualDiscount:'',
			manualDiscountPercent:'',
			promocodes:null,
			promoCode:'',
			promoCodeDiscount:0,
			promoCodeDiscountPercent:0,
			promoCodeName:'',
			delivery:null,
			deliveryCost:0,
			deliveryFreeAmount:0,
			deliveryCostManual:undefined,
			deliveryTrafficFactor:1,
			isPaid:null,
			surrender:'',
			cutlery:'',
			date:'',
			room:'',
			entrance:'',
			intercom:'',
			floor:'',
			status:comonStatus.ACTIVE,
			clientName:null,
			clientPhone:null,
			clientAddress:null,
			clientAddressId:0,
			clientComment:'',
			moneyCash:undefined,
			moneyPOS:undefined,
			moneyCashFocus:false,
			moneyPOSFocus:false,
			moneyTotal:null,
			dailyReport:null,
			ordersStats:null,
			sectionsreport:null,
			pin:undefined,
			amountTerminal:undefined,
			amountTransfer:undefined,
			cashPaper:3000,
			cashCoin:500,
			casherOrders:0,
			logistCasher:0,
			casherLogist:0,
			casherExchange:0,
			casherFinal:0,
			isall:false,
			pinFocus:false,
			isnewcall:false,
			isdelivery:false,
			isordershow:false,
			isfullscreen:false,
			isordersearch:false,
			isordersearchinfo:false,
			isordersdate:false,
			isorderscancel:false,
			orderaddstep:1,
			amountTerminalFocus:false,
			amountTransferFocus:false,
			showaddresssearch:false,
			modalshowprompt:false,
			modalshowpromptpaid:false,
			modalshowpromptpaidhall:false,
			modalshowpromptclose:false,
			modalshowpromptcalc:false,
			modalshowpromptcancel:false,
			modalshowpromptlogist:false,
			modalshowpromptdayshiftclose:false,
			modalshowpromptdayshiftclosefinish:false,
			modalshowreport:false,
			modalshowsectionsreport:false,
			modalshowcashin:false,
			modalshowcashout:false,
			modalshowpin:false,
			modalshowtokassa:false,
			dayshiftreload:false,
			isordersave:false,
			iswharehouse:false,
			iswharehouserequest:false,
			isadmin:false,
			iserror:false,
			iserrordayshift:false,
			loading:true
		};
	}
	timerId = null;
	promoCodeTimerId = null;
	printTimerId = null;
	allowStatuses = [
		orderStatus.OPERATOR_WAIT,
		orderStatus.COOK_WAIT,
		orderStatus.COOK_ACCEPTED,
		orderStatus.COOK_DONE,
		orderStatus.COLLECTOR_WAIT,
		orderStatus.COLLECTOR_ACCEPTED,
		orderStatus.COLLECTOR_DONE,
		orderStatus.LOGIST_WAIT,
		orderStatus.LOGIST_ACCEPTED,
		orderStatus.LOGIST_DONE,
		orderStatus.COURIER_WAIT,
		orderStatus.COURIER_ACCEPTED,
		orderStatus.COURIER_IN_ROUTE,
		orderStatus.COURIER_DONE,
		orderStatus.FINISH,
		orderStatus.ARCHIVE
	];
	allowSkipStatuses = [
		orderStatus.COOK_WAIT,
		orderStatus.COOK_ACCEPTED,
		orderStatus.COOK_DONE,
		orderStatus.COLLECTOR_WAIT,
		orderStatus.COLLECTOR_ACCEPTED,
		orderStatus.COLLECTOR_DONE
	];
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.dataGet();
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		this.timerId = null;
		clearInterval(this.printTimerId);
		this.printTimerId = null;
	}
	dataGet = async () => {
		this.setState({loading:true,iserror:false,iserrordayshift:false});
		const user = ls('user');
		try {
			const settings = ls('settings');
			const activearea = ls('activearea');
			const areas = await Areas.getAllActive();
			const dayshift = await DayShifts.getCurrent(activearea.id);
			if (dayshift === null) {
				this.setState({iserrordayshift:true,loading:false});
				return;
			}
			this.setState({user,
				inn:Settings.getByKey('inn', settings).value,
				noOperator:parseInt(Settings.getByKey('no-operator', settings).value) === 1,
				noCook:parseInt(Settings.getByKey('no-cook', settings).value) === 1,
				noCollector:parseInt(Settings.getByKey('no-collector', settings).value) === 1,
				noLogist:parseInt(Settings.getByKey('no-logist', settings).value) === 1,
				deliveryCost:parseInt(Settings.getByKey('delivery-cost', settings).value),
				deliveryFreeAmount:parseInt(Settings.getByKey('delivery-amount-free', settings).value),
				deliveryTrafficFactor:parseFloat(Settings.getByKey('delivery-traffic-factor', settings).value),
				areas:areas.data,
				activearea,
				dayshift,
				loading:true
			});
			this.cashInShow(dayshift);
			await this.ordersPanelsUpdate();
			this.timerId = setInterval(this.ordersPanelsUpdate, timers.NEW_ORDERS_GET_OPERATOR);
			this.printTimerId = setInterval(this.printerUpdate, timers.PRINTER_CHECK);
			Products.getAllActive((data) => {
				const categories = [];
				data.forEach((v,i) => {
					if (v.categoryId !== 0) {
						const c = categories.find(f => f.categoryId === v.categoryId);
						if (c === undefined) categories.push(v);
					}
				});
				this.setState({products:data,categories});
			});
			Sections.getAll((data) => this.setState({sections:data.sort((a) => a.sortOrder ? -1 : 1)}));
			Discounts.getAll((data) => this.setState({discounts:data}));
		} catch (ex) {
			this.setState({loading:false,iserror:true});
		}
	}
	ordersPanelsUpdate = async () => {
		const statuses = [
			orderStatus.OPERATOR_WAIT,
			orderStatus.COOK_WAIT,
			orderStatus.COOK_ACCEPTED,
			orderStatus.COOK_DONE,
			orderStatus.COLLECTOR_WAIT,
			orderStatus.COLLECTOR_ACCEPTED,
			orderStatus.COLLECTOR_DONE,
			orderStatus.LOGIST_WAIT,
			orderStatus.LOGIST_ACCEPTED,
			orderStatus.LOGIST_DONE,
			orderStatus.COURIER_WAIT
		];
		const {activearea,dayshift} = this.state;
		const orders = await Orders.getDayShiftOrders(activearea.id, dayshift.id);
		if (orders) {
			const o = orders.data.filter(f => statuses.includes(f.status));
			o.sort((a,b) => a.dateCreate > b.dateCreate ? -1 : 1);
			const ordersdate = await Orders.getOnDateGet(todayGet());
			const o2 = ordersdate.data.filter(f => statuses.includes(f.status) && f.areaId === activearea.id);
			o2.sort((a,b) => a.dateCreate > b.dateCreate ? -1 : 1);
			const orderscancel = orders.data.filter(f => f.status === orderStatus.CANCEL);
			this.setState({orders:o,ordersdate:o2,orderscancel,loading:false});
		}
		else this.setState({loading:false,iserror:true});
		this.statUpdate();
	}
	statUpdate = async () => {
		const {activearea,dayshift} = this.state;
		if (dayshift === null || activearea === null) return;
		const ordersAll = await this.reportOrdersGet(activearea.id, dayshift.id);
		const ordersPickup = ordersAll.filter(f => f.isPickup === orderPickup.PICKUP);
		const ordersDelivery = ordersAll.filter(f => f.isPickup === orderPickup.DELIVERY);
		const ordersStats = {
			pickup: {
				count:ordersPickup.length,
				amount:this.reportSumCalc(ordersPickup)
			},
			delivery: {
				count:ordersDelivery.length,
				amount:this.reportSumCalc(ordersDelivery)
			}
		};
		this.setState({ordersStats});
	}
	printerUpdate = async () => {
		const {user,activearea,dayshift} = this.state;
		const orders = await OrderPrints.getActive(activearea.id, dayshift.id);
		if (orders && orders.data) {
			orders.data.forEach((v,i) => {
				Orders.get(v.orderId, (data) => {
					if (data) {
						if (data.status === orderStatus.CANCEL) {
							Printer.cancel(data);
							OrderPrints.update(v.id, {status:comonStatus.IN_ACTIVE});
							Events.add(user.id, Texts.order_check.add, null, JSON.stringify({status:comonStatus.IN_ACTIVE}), v.id, eventType.ORDER_CHECK);
						} else {
							OrderItems.getSync(data.id, (items) => {
								this.soundPlay();
								if (data.isPrinted === 0) {
									if (data.type === orderType.OPERATOR_HALL) {
										//Printer.check(user.name, this.state.inn, data, items, true);
										//const printed = {isPrinted:1};
										//Orders.update(data.id, printed, () => Events.add(user.id, Texts.order.update, JSON.stringify({isPrinted:0}), JSON.stringify(printed), data.id, eventType.ORDER));
									}
								}
								if (data.products) {
									if (items.length === 0) Logs.add(`print error. id: ${v.orderId}`);
									else {
										let p = data.products.split('\r\n');
										if (p.length !== items.length) {
											p = data.products.split('\r');
											if (p.length !== items.length)
												p = data.products.split('\n');
										}
										if (p.length === items.length) {
											Printer.check(user.name, this.state.inn, data, items, false);
											Printer.check(user.name, this.state.inn, data, items, false);
											Printer.cook(data, items);
											Printer.collect(data, items);
											OrderPrints.update(v.id, {status:comonStatus.IN_ACTIVE});
											Events.add(user.id, Texts.order_check.add, null, JSON.stringify({status:comonStatus.IN_ACTIVE}), v.id, eventType.ORDER_CHECK);
										} else console.log(`printer skip: id: ${v.orderId}, items: ${items.length}, producst:${p.length}`);
									}
								}
							});
						}
					} else console.log('print error update')
				});
			});
		}
		PrinterJobs.getActive(activearea.id, (data) => {
			if (data) {
				data.forEach((v) => {
					const data = JSON.parse(v.data);
					if (v.type === printerJobType.DAILY_REPORT) Printer.report.logist(data);
					if (v.type === printerJobType.COURIERS_DEBT) Printer.report.couriersDebt(data);
					PrinterJobs.update(v.id, {status:comonStatus.IN_ACTIVE});
				});
			}
		});
	}
	storeGet = async (areaId) => {
		const s = await Stores.getAreaAsync(areaId);
		const store = s.data[0]
		const requests = await Stores.requestsStoreGetAsync(store.id, requestType.AUTO, requestStatus.CREATE)
		const balances = await Stores.balancesGet();
		const ingredients = await Stores.ingredientsGetAsync();
		const semifinish = await Stores.semifinishesGetAsync();
		const b = balances.data.filter(f => f.storeId === store.id);
		b.forEach((v) => {
			v.min = v.cardId ? this.minGet(v.cardId, semifinish.data) : this.minGet(v.ingredientId, ingredients.data);
			v.defaultCount = v.cardId ? this.defaultGet(v.cardId, semifinish.data) : this.minGet(v.ingredientId, ingredients.data);
			v.price = v.cardId ? this.priceGet(v.cardId, semifinish.data) : this.minGet(v.ingredientId, ingredients.data);
		});
		const items = b.filter(f => f.min >= f.quantity);
		const requestids = items.map((v) => v.id);
		this.setState({store:{id:store.id,name:store.name,items},requests:requests.data,requestids,requestrequestids:requestids});
	}
	minGet = (id, ingredients) => ingredients.find(f => f.id === id).minimumCount || 1;
	defaultGet = (id, ingredients) => ingredients.find(f => f.id === id).defaultCount || 1;
	priceGet = (id, ingredients) => ingredients.find(f => f.id === id).price || 1;
	soundPlay = () => new Audio(require('../../../Audio/signal.mp3')).play();
	additionalStatus = (order) => {
		if (this.state.noOperator) return null;
		const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-operator', settings).value);
		return (order.dateCreate + timeDelay) < now ? styles.alarm : null;
	}
	amountGet = (order) => `${moneyFormat(order.priceFull, false)} ₽`;
	orderSelect = async (order) => {
		const products = await OrderItems.get(order.id);
		this.setState({data:order,orderproducts:products.data,orderid:order.id,isordershow:false}, () => this.setState({isordershow:true}));
	}
	orderShowClose = () => this.setState({isordershow:false,data:null,orderid:0});
	nextStatusGet = () => {
		let status = orderStatus.COOK_WAIT;
		if (this.state.noCook) {
			status = orderStatus.COLLECTOR_WAIT;
			if (this.state.noCollector) {
				status = orderStatus.LOGIST_WAIT;
				if (this.state.noLogist) status = orderStatus.COURIER_WAIT;
			}
		}
		return status;
	}
	orderAccept = async () => {
		const {user,data} = this.state, status = this.nextStatusGet();
		const ds = await this.orderDayShiftSet();
		if (ds === null) return;
		const statuses = {
			orderId:data.id,
			userId:user.id,
			userName:user.name,
			status
		};
		OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		const order = {status,dayShiftId:ds.id};
		Orders.update(data.id, order, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:data.status}), JSON.stringify(order), data.id, eventType.ORDER);
			this.ordersPanelsUpdate();
			this.modalHide();
			this.orderCreateClose();
			this.orderShowClose();
			this.successMessageShow();
		});
	}
	orderClose = () => this.setState({modalshowpromptclose:true});
	orderDayShiftSet = async () => {
		const {activearea} = this.state;
		const ds = await DayShifts.getCurrent(activearea ? activearea.id : 0);
		if (ds === null) {
			alertShow('<b>Невозможно принять заказ!</b><br/>Смена не открыта! Обратитесь к вашему управляющему', true);
			return null;
		}
		return ds;
	}
	orderCreateClose = () => {
		this.setState({orderaddstep:1,isordershow:false,isnewcall:false,isdelivery:false,isfullscreen:false,isordersearch:false,isordersearchinfo:false,isordersdate:false,isorderscancel:false,
			orderitems:[],sectionId:0,sectionLink:null,sectionName:null,categoryId:0,categoryName:null,client:null,
			showaddresssearch:false,showclientsearch:false,
			isordersave:false,
			orderproducts:[],
			clientName:null,clientPhone:null,clientAddress:null,clientAddressId:0,clientComment:null,isPaid:null,
			addresses:null,addressfull:null,addressClient:null,
			room:null,entrance:null,intercom:null,floor:null,status:comonStatus.ACTIVE,
			clients:[],promocodes:null,promoCode:'',
			delivery:null,deliveryCostManual:undefined,
			manualDiscount:null,manualDiscountPercent:null,
			surrender:null,cutlery:null,date:null,
			moneyTotal:null,dailyReport:null,
			orderid:0
		});
		this.modalHide();
	}
	clientSave = () => {
		const phone = phoneNormalize(this.state.clientPhone);
		const client = {
			name:this.state.clientName,
			phone,
			addresses:[],
			status:clientStatus.ACTIVE
		}
		Clients.add(client, (res) => {
			client.id = res.data.id;
			Events.add(this.state.user.id, Texts.client.add, null, JSON.stringify(client), client.id, eventType.CLIENT);
			this.setState({client,orderaddstep:3});
		 }, (err) => {
			alertShow(errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError, true);
			Logs.add(err);
		 });
	}
	deliveryInfoGet = async (latitude, longitude) => {
		const delivery = await DeliveryAreas.getByArea(latitude, longitude);
		if (delivery) this.setState({delivery:delivery.data,deliveryCost:delivery.data.price,deliveryFreeAmount:delivery.data.priceFree});
	}
	handleStreet = () => {
		const {addressClient} = this.state;
		if (addressClient && addressClient.length > 5) {
			this.setState({showaddresssearch:true,addresses:[]});
			const q = `${MAP.defaultCity}, ${addressClient}`;
			Maps.locationGetAddress(q, (res) => {
				const addresses = [];
				if (res.response) {
					res.response.GeoObjectCollection.featureMember.forEach((v) => {
						const name = v.GeoObject.name;
						const description = v.GeoObject.description;
						addresses.push({
							name,
							description,
							latitude:parseFloat(v.GeoObject.Point.pos.split(' ')[1]),
							longitude:parseFloat(v.GeoObject.Point.pos.split(' ')[0])
						});
					});
				}
				this.setState({addresses});
			});
		} else this.setState({showaddresssearch:false});
	}
	handleStreetAddress = (addressClient) => this.setState({addressClient,showaddresssearch:false});
	handleAddressRoom = (room) => this.setState({room});
	handleAddressEntrance = (entrance) => this.setState({entrance});
	handleAddressFloor = (floor) => this.setState({floor});
	handleAddressIntercom = (intercom) => this.setState({intercom});
	addressCombine = (address) => {
		const addressfull = [this.addressStreetClean(address.address)];
		if (address.room) addressfull.push(`кв. ${address.room}`);
		if (address.entrance) addressfull.push(`подъезд ${address.entrance}`);
		if (address.floor) addressfull.push(`этаж ${address.floor}`);
		if (address.intercom) addressfull.push(`домофон ${address.intercom}`);
		return addressfull.join(', ').trim();
	}
	addressStreetClean = (address) => address.replace('ул.', '').trim();
	searchAddressSelect = (item) => {
		const addressfull = {
			clientId:this.state.client.id,
			address:item.name,
			latitude:item.latitude,
			longitude:item.longitude,
			status:comonStatus.ACTIVE
		};
		const clientAddress = addressfull.address;
		this.setState({addressfull,showaddresssearch:false,addressClient:clientAddress,clientAddress});
	}
	handleClient = (clientPhone) => {
		if (clientPhone === null || clientPhone.length === 1) this.setState({showclientsearch:false,clients:null,clientPhone})
		if (clientPhone && clientPhone.length > 1) Clients.find(clientPhone, (data) => this.setState({showclientsearch:true,clients:data,clientPhone}));
	}
	handlePhone = (clientPhone) => this.setState({clientPhone})
	searchClientSelect = (client) => {
		const addresses = client.addresses, addressfull = client.addresses && client.addresses.length ? client.addresses[0] : null;
		this.setState({
			addresses,
			addressfull,
			client,
			clientPhone:client.phone,
			room:addressfull ? addressfull.room : '',
			entrance:addressfull ? addressfull.entrance : '',
			intercom:addressfull ? addressfull.intercom : '',
			floor:addressfull ? addressfull.floor : '',
			address:addressfull ? addressfull.address : '',
			addressClient:addressfull ? addressfull.address : '',
			clientAddressId:addressfull ? addressfull.id : '',
			showclientsearch:false,orderaddstep:3}, () => {
				if (addressfull)
					this.clientAddressSet(addressfull.id);
		});
	}
	clientCurrentAddressGet = (addresses) => {
		if (addresses === null || addresses.length === 0) return null;
		const address = addresses.find(f => f.status === comonStatus.ACTIVE);
		return address ? address.address : addresses[addresses.length-1].address;
	}
	stepNameGet = (step) => {
		switch (step) {
			case 1: default: return 'Поиск клиента по телефону';
			case 2: return 'Новый клиент';
			case 3: return 'Адрес доставки';
			case 4: return `Меню${this.state.sectionName ? ' / ' + this.state.sectionName : ''}${this.state.categoryName ? ' / ' + this.state.categoryName : ''}`;
			case 5: return 'Скидки, промокоды, комментарий';
		}
	}
	orderNext = (e) => {
		const {orderaddstep,orderitems,clientPhone} = this.state;
		switch (orderaddstep) {
			case 1:
				break;
			case 2:
				if (phoneNormalize(clientPhone) === null) return;
				this.clientSave();
				break;
			case 3:
				this.addressSave();
				break;
			case 4:
				if (orderitems.length === 0) return;
				this.setState({orderaddstep:5});
				break;
			case 5:
				this.setState({modalshowprompt:true});
				break;
			default:
				return;
		}
	}
	clientAdd = () => this.setState({orderaddstep:2});
	orderPaidShow = () => {
		if (this.state.isdelivery) this.setState({modalshowpromptpaid:true});
		else this.setState({modalshowpromptpaidhall:true});
	}
	orderPaid = (ispaid, paymentType) => {
		this.setState({isPaid:ispaid?orderPaid.PAID:orderPaid.NOT_PAID}, () => {
			if (paymentType !== undefined) this.setState({paymentType}, () => this.orderSave());
			else this.orderSave();
		});
	}
	orderSave = async () => {
		this.modalHide();
		if (this.state.isordersave) return;
		this.setState({isordersave:true});
		const {user,client,promocodes,addressfull,date,isdelivery} = this.state, status = this.nextStatusGet();
		const ds = await this.orderDayShiftSet();
		if (ds === null) return;
		const orderitems = this.quantityGroupGet(this.state.orderitems);
		const data = {
			areaId:this.state.activearea.id,
			dayShiftId:ds.id,
			price:this.state.price,
			priceFull:this.priceFullGet(),
			products:orderitems.map((v,i) => `${i+1}. ${v.name||v.productName}: ${v.priceDiscount||v.price} ₽ (${v.quantity} шт.)`).join('\r\n'),
			deliveryIsFree:1,
			isPickup:1,
			type:orderType.OPERATOR_HALL,
			status,
			paymentType:this.state.paymentType,
			transactionPaymentType:orderTransactionPaymentType.OFFLINE,
			isPaid:orderPaid.PAID,
			comment:this.state.clientComment,
			target:targetType.OPERATOR
		};
		if (isdelivery) {
			data.clientId = client.id;
			data.clientName = client.name;
			data.phone = client.phone;
			data.address = this.addressCombine(this.state.addressfull);
			data.latitude = addressfull.latitude;
			data.longitude = addressfull.longitude;
			data.deliveryCost = this.state.deliveryCost;
			data.deliveryCostManual = this.state.deliveryCostManual || 0;
			data.deliveryIsFree = this.state.price >= this.state.deliveryFreeAmount ? 1 : 0;
			data.type = orderType.OPERATOR;
			data.surrender = this.state.surrender;
			data.cutlery = this.state.cutlery;
			data.isPickup = 0;
			data.isPaid = orderPaid.PAID;
			if (!empty(date)) data.date = Math.round(new Date(date).getTime() / 1000);
		}
		if (promocodes) {
			data.promoCode = promocodes.code;
			data.promoCodeDiscount = promocodes.discount;
			data.promoCodeDiscountPercent = promocodes.discountPercent;
		} else {
			if (this.state.manualDiscount || this.state.manualDiscountPercent) {
				data.manualDiscount = this.state.manualDiscount;
				data.manualDiscountPercent = this.state.manualDiscountPercent;
				data.priceDiscount = this.state.priceDiscount;
			}
			if (this.state.discountId) {
				data.discountId = this.state.discountId;
				data.priceDiscount = this.state.priceDiscount;
				data.discount = this.state.discount;
				data.discountPercent = this.state.discountPercent;
			}
		}
		Orders.add(data, (res) => {
			const id = res.data.id;
			this.setState({data:{...data,id}});
			Events.add(user.id, Texts.order.add, null, JSON.stringify(data), id, eventType.ORDER);
			const statuses = {
				orderId:id,
				userId:user.id,
				userName:user.name,
				status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			if (promocodes) {
				if (promocodes.count > 0) {
					const promo = {total:promocodes.total+1};
					PromoCodes.update(promocodes.id, promo, () => Events.add(user.id, Texts.promocodes.update, JSON.stringify(promocodes), JSON.stringify(promo), promocodes.id, eventType.PROMOCODE));
				}
				const clientpromo = {
					promoCodeId:promocodes.id,
					code:promocodes.code,
					orderId:id,
					discount:promocodes.discount,
					discountPercent:promocodes.discountPercent
				};
				if (client) {
					clientpromo.clientId = client.id;
					clientpromo.clientName = client.name;
				}
				PromoCodeClients.add(clientpromo, (res) => Events.add(user.id, Texts.client_promocodes.update, null, JSON.stringify(clientpromo), res.data.id, eventType.CLIENT_PROMOCODE));
			}
			for (let i = 0, j = orderitems.length; i < j; i++) {
				const v = orderitems[i];
				const item = {
					orderId:id,
					sectionId:v.sectionId,
					sectionName:v.sectionName,
					productId:v.id,
					productName:v.name||v.productName,
					price:v.price,
					priceDiscount:v.priceDiscount,
					discount:v.discount,
					discountPercent:v.discountPercent,
					measure:v.measure,
					measureType:v.measureType,
					quantity:v.quantity
				};
				try {
					setTimeout(async () => await OrderItems.add(item), 100);
				} catch (ex) {
					Logs.add(`orderitems operator error: ${id}, ${ex}`);
				}
			}
			const transaction = {
				orderId:id,
				amount:data.priceFull,
				paymentType:transactionPaymentType.OFFLINE,
				status:transactionStatus.ADD
			};
			if (client) transaction.clientId = client.id;
			Transactions.add(transaction, (res) => Events.add(user.id, Texts.transaction.add, null, JSON.stringify(transaction), res.data.id, eventType.TRANSACTION));
			OrderPrints.add({areaId:data.areaId,dayShiftId:data.dayShiftId,orderId:id,status:comonStatus.ACTIVE});
			this.modalHide();
			this.orderCreateClose();
			this.orderShowClose();
			this.successMessageShow();
			this.ordersPanelsUpdate();
			Stores.balanceSell(id);
		}, (err) => {
			alertShow(Texts.errors.internalError, true);
			Logs.add(err);
		});
	}
	addressSave = () => {
		const {room,entrance,floor,intercom,clientAddressId,client,user} = this.state, old = this.state.addressfull;
		const addressfull = {...this.state.addressfull,room,entrance,floor,intercom,status:comonStatus.ACTIVE,comment:''};
		if (clientAddressId === 0) {
			ClientAddresses.add(addressfull, (res) => {
				const id = parseInt(res.data.id);
				addressfull.id = id;
				client.addresses.push(addressfull);
				this.setState({client,clientAddressId:id,addressfull,orderaddstep:4});
				Events.add(user.id, Texts.client_address.add, null, JSON.stringify(addressfull), id, eventType.CLIENT_ADDRESS);
				this.deliveryInfoGet(addressfull.latitude, addressfull.longitude);
			});
		} else {
			ClientAddresses.update(clientAddressId, addressfull, () => {
				const addresses = this.state.addresses.map((v,i) => {
					if (v.id === clientAddressId) v = addressfull;
					return v;
				});
				client.addresses = addresses;
				this.setState({client,clientAddressId,addressfull,orderaddstep:4});
				Events.add(user.id, Texts.client_address.update, JSON.stringify(old), JSON.stringify(addressfull), clientAddressId, eventType.CLIENT_ADDRESS);
				this.deliveryInfoGet(addressfull.latitude, addressfull.longitude);
			});
		}
	}
	clientAddressSet = (clientAddressId) => {
		const address = this.state.client.addresses.find(f => f.id === clientAddressId);
		if (address) {
			this.setState({clientAddressId,addressfull:address,clientComment:address.comment,addressClient:address.address,room:address.room,floor:address.floor,entrance:address.entrance,intercom:address.intercom});
			this.deliveryInfoGet(address.latitude, address.longitude);
		}
	}
	handleForm = (e) => this.setState({[e.target.name]:e.target.value});
	handleAddressSelect = (e) => {
		const clientAddressId = parseInt(e.target.value);
		if (isNaN(clientAddressId)) {
			this.addressAdd();
			return;
		}
		this.clientAddressSet(clientAddressId);
	}
	handleDiscountForm = (e, maxvalue, nocalc) => {
		let value = parseInt(e.target.value);
		value = value > maxvalue ? maxvalue : value;
		this.setState({[e.target.name]:isNaN(value)?'':value}, () => nocalc ? {} : this.priceCalc());
	}
	handleCommentForm = (e) => {
		const clientComment = e.target.value;
		this.setState({clientComment});
		const addresses = this.state.client.addresses.map((v,i) => {
			if (this.state.clientAddressId === v.id) v.comment = clientComment;
		});
		this.setState({addresses});
	};
	handleDeliveryForm = (e) => {
		const maxvalue = 299;
		let value = parseInt(e.target.value);
		value = value > maxvalue ? maxvalue : value;
		if (empty(value) || isNaN(value)) value = undefined;
		this.setState({deliveryCostManual:value});
	}
	handlePromoCodeForm = (promoCode) => {
		clearTimeout(this.promoCodeTimerId);
		this.promoCodeTimerId = null;
		this.promoCodeTimerId = setTimeout(() => PromoCodes.check(promoCode, (data) => this.setState({promocodes:data}, () => this.priceCalc())), 2000);
		this.setState({promoCode:promoCode ? promoCode.toUpperCase() : promoCode,promocodes:null});
	}
	handleDateForm = (e) => this.setState({date:e.target.value});
	dateClear = () => this.setState({date:''});
	sectionSelect = (id, name, link, categoryName) => this.setState({sectionId:id,sectionName:name,sectionLink:link,categoryName});
	categorySelect = (id, sectionId, name, sectionName) => this.setState({categoryId:id,sectionId,categoryName:name,sectionName});
	itemsShow = () => {
		const {sectionId,categoryId,sectionLink,categories} = this.state;
		const cats = categories.filter(f => f.sectionId === sectionId);
		if (categoryId === 0 && cats.length > 0) {
			return <>
				<div className={styles.categories}>
					<div className={cx(styles.catitem,styles.back)} onClick={() => this.categorySelect(0, 0, null, null)}><b>Назад</b></div>
					{cats.map((v,i) => <div key={i} className={cx(styles.catitem,styles.cat)} onClick={() => this.categorySelect(v.categoryId, sectionId, v.categoryName, v.sectionName)}>
						<img src={require(`../../../Images/icons/sections/${sectionLink}.svg`)} alt={this.state.sectionName} />
						<div>{v.categoryName}</div>
					</div>)}
				</div>
				{this.productsShow(0, sectionId, false)}
			</>
		}
		return this.productsShow(categoryId, sectionId, true);
	}
	productsShow = (categoryId, sectionId, isback) => {
		const data = this.state.products.filter(f => f.sectionId === sectionId && f.categoryId === categoryId);
		return (
			<div className={styles.products}>
				{isback ? <div className={styles.productitem} onClick={() => categoryId === 0 ? this.sectionSelect(0, null, null, null) : this.categorySelect(0, sectionId, null, this.state.sectionName)}><b>Назад</b></div> : null}
				{data.map((v,i) => <div key={i} className={cx(styles.productitem,styles[`cat${v.categoryId}`])} onClick={() => this.productSelect(v)}>
						{v.name}
						<span><b>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, false)} ₽</b> / {v.measure} {measureTypeName[v.measureType]}</span>
					</div>)}
			</div>
		);
	}
	productSelect = (v) =>  this.setState({orderitems:[...this.state.orderitems,v]}, () => this.priceCalc());
	productRemove = (idx) => {
		const orderitems = [];
		this.state.orderitems.forEach((v,i) => {
			if (i !== idx) orderitems.push(v);
		});
		this.setState({orderitems}, () => this.priceCalc());
	}
	priceCalc = async () => {
		const {orderitems,discounts,promocodes,isdelivery} = this.state;
		const price = orderitems.reduce((a, b) => a + (b.priceDiscount||b.price), 0);
		const discountGet = (v, price) => {
			if (v.discount) return v.discount;
			else if (v.discountPercent) return Math.ceil((price * v.discountPercent) / 100);
			return 0;
		};
		const priceDiscountGet = (v, price) => {
			if (v.discount) return price - v.discount;
			else if (v.discountPercent) return price - discountGet(v, price);
			return 0;
		};
		const timeCheck = (v) => {
			const start = parseInt(v.conditionTimeStart.replace(':', '')), end = parseInt(v.conditionTimeEnd.replace(':', '')),
				time = dateSegmentsGet().hour * 100 + dateSegmentsGet().minutes;
			return time >= start && time < end;
		};
		let discountId = 0, priceDiscount = 0, discountName, discount = 0, prefix = '';
		if (promocodes) {
			if (promocodes.discount && promocodes.discount < price) {
				discount = promocodes.discount;
				priceDiscount = price - discount;
				prefix = ` -${promocodes.discount} ₽`
			}
			else if (promocodes.discountPercent) {
				discount = Math.ceil((price * promocodes.discountPercent) / 100);
				priceDiscount = price - discount;
				prefix = ` -${promocodes.discountPercent}% (${discount} ₽)`
			}
			if (discount !== 0) {
				discountName = `промокод ${prefix}`;
				this.setState({price,priceDiscount,discountName,discount});
				return;
			}
		}
		const {manualDiscount,manualDiscountPercent} = this.state;
		if (manualDiscount || manualDiscountPercent) {
			if (manualDiscount && manualDiscount < price) {
				discount = manualDiscount;
				priceDiscount = price - discount;
				prefix = `-${manualDiscount} ₽`
			}
			else if (manualDiscountPercent) {
				discount = Math.ceil((price * manualDiscountPercent) / 100);
				priceDiscount = price - discount;
				prefix = `-${manualDiscountPercent}% (${discount} ₽)`
			}
			if (discount !== 0) discountName = `персональная ${prefix}`;
		}
		else {
			if (isdelivery) {
				discounts.forEach((v) => {
					let pd = 0, d = 0;
					if (v.conditionTimeStart && v.conditionTimeEnd && (v.conditionAmount !== 0 && price >= v.conditionAmount)) {
						if (timeCheck(v)) {
							pd = priceDiscountGet(v, price);
							d = discountGet(v, price);
						}
					}
					else if (v.conditionAmount !== 0 && price >= v.conditionAmount) {
						pd = priceDiscountGet(v, price);
						d = discountGet(v, price);
					}
					else if (v.conditionTimeStart && v.conditionTimeEnd) {
						if (timeCheck(v)) {
							pd = priceDiscountGet(v, price);
							d = discountGet(v, price);
						}
					}
					if ((priceDiscount === 0 && pd > priceDiscount) || (pd > 0 && pd < priceDiscount)) {
						priceDiscount = pd;
						discount = d;
						discountId = v.id;
						discountName = v.name;
					}
				});
			}
		}
		this.setState({price,priceDiscount,discountId,discountName,discount});
	}
	priceFullGet = () => (this.state.priceDiscount ? this.state.priceDiscount : this.state.price) + (this.state.isdelivery ? (this.state.deliveryCostManual !== undefined ? this.state.deliveryCostManual : (this.state.price >= this.state.deliveryFreeAmount ? 0 : this.state.deliveryCost)) : 0);
	shift = async (isopen) => {
		const {user,activearea} = this.state;
		const ds = await DayShifts.getCurrent(activearea.id);
		const data = {status:comonStatus.ACTIVE,userId:user.id,areaId:activearea.id};
		if (isopen) {
			if (ds) return alertShow('Смена уже открыта!', true);
			DayShifts.add({...data,date:todayGet(),dateStart: dateNow()});
			Printer.shift(user.name, this.state.inn, true);
			alertShow('Смена успешно открыта!');
			setTimeout(() => window.location.reload(), 1000);
		} else {
			if (ds === null) return alertShow('Нет открытой смены!', true);
			this.setState({modalshowpromptdayshiftclose:true});
		}
	}
	shiftClose = async () => {
		const statuses = [orderStatus.CANCEL,orderStatus.REFUND,orderStatus.HOLD,orderStatus.HIDE,orderStatus.ARCHIVE,orderStatus.ROBOT_PREPARE,orderStatus.KIOSK_PREPARE];
		const {user,activearea} = this.state;
		const now = dateNow();
		const ds = await DayShifts.getCurrent(activearea.id);
		if (ds === null) return alertShow('Нет открытой смены!', true);
		try {
			const dayShiftId = ds.id, areaId = activearea.id;
			const ordersOldData = await this.reportOrderOldGet()
			const ordersOld = ordersOldData.data.filter(f => f.areaId === areaId)
			const ordersNow = await this.reportOrdersGet(areaId, dayShiftId)
			const ordersAll = [...ordersNow, ...ordersOld]
			const allDelivery = ordersAll.filter(f => f.isPickup === orderPickup.DELIVERY)
			const allPaid = allDelivery.filter(f => f.isPaid === orderPaid.PAID && f.isQR === 0)
			const allQR = allDelivery.filter(f => f.isQR === 1)
			const couriersDebt = await Accountings.report.inWork(areaId, dayShiftId)
			const terminal = await Accountings.report.finish(areaId, dayShiftId)
			const couriersDebtOld = await Accountings.report.inWorkOld(areaId, dayShiftId)
			const terminalOld = await Accountings.report.finishOld(areaId, dayShiftId)
			const logistClose = await Accountings.report.logist(areaId, dayShiftId)
			const cashin = await MoneyTransfers.get(areaId, dayShiftId, moneyTransferType.DAILY_CHANGE)
			const amountKassa = cashin.data.length > 0 ? cashin.data[0].amount : 0
			const kassa = await MoneyTransfers.get(areaId, dayShiftId, moneyTransferType.CASHIER_LOGIST)
			const kassaLogist = kassa.data.length > 0 ? kassa.data.reduce((a, b) => a + b.amount, 0) : 0
			const dailyReport = {
				delivery: {
					name:'Все доставки',
					count:allDelivery.length,
					amount:this.reportSumCalc(allDelivery)
				},
				paid: {
					name:'Оплачены',
					count:allPaid.length,
					amount:this.reportSumCalc(allPaid)
				},
				qr: {
					name:'QR',
					count:allQR.length,
					amount:this.reportSumCalc(allQR)
				},
				terminal: {
					name:'Наличные в терминале',
					amount:terminal.data
				},
				couriersDebt: {
					name:'Текущие долги',
					amount:couriersDebt.data - couriersDebtOld.data
				},
				terminalOld: {
					name:'Наличные в терминале за другую смену',
					amount:terminalOld.data
				},
				couriersDebtOld: {
					name:'Прошлые долги',
					amount:couriersDebtOld.data
				},
				logistClose: {
					name:'Ручное закрытие долгов',
					amount:logistClose.data
				},
				kassa: {
					name:'Наличные для размена',
					amount:amountKassa
				},
				kassaLogist: {
					name:'Наличные от кассира',
					amount:kassaLogist
				},
				logist: {
					name:'ИТОГО',
					amount:0,
					isfinal:true
				}
			};
			dailyReport.logist.amount = (dailyReport.delivery.amount + dailyReport.kassa.amount + dailyReport.kassaLogist.amount + dailyReport.terminalOld.amount)
								- dailyReport.paid.amount - dailyReport.qr.amount - dailyReport.terminal.amount - dailyReport.couriersDebt.amount;
			const d = {
				dayShiftId,
				areaId,
				date:todayGet(),
				data:JSON.stringify(dailyReport)
			};
			await DayShiftReports.add(d);
		} catch (ex) {
			console.log(666, ex);
			Logs.add(`shiftClose error: ${ex}`);
		}
		const o = await Orders.getDayShiftOrders(activearea.id, ds.id);
		const orders = o.data.filter(f => f.isPrinted === 0 && (f.date === 0 || f.date < now) && !statuses.includes(f.status));
		Printer.shift(user.name, this.state.inn, false);
		await orders.forEach(async (v,i) => {
			const printed = {isPrinted:1,status:orderStatus.ARCHIVE};
			Orders.update(v.id, printed, () => Events.add(user.id, Texts.order.update, JSON.stringify({isPrinted:0}), JSON.stringify(printed), v.id, eventType.ORDER));
			const orderproducts = await OrderItems.get(v.id);
			Printer.check(user.name, this.state.inn, v, orderproducts.data, true);
		});
		DayShifts.update(ds.id, {status:comonStatus.IN_ACTIVE,dateEnd:now});
		this.modalHide();
		setTimeout(() => this.setState({modalshowpromptdayshiftclosefinish:true}), 1000);
	}
	shiftCloseSet = () => this.setState({dayshiftreload:true}, () => this.modalHide());
	dayShiftReload = () => window.location.reload();
	reportOrdersGet = async (areaId, dayShiftId) => {
		const now = dateNow();
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => (f.date === 0 || f.date < now) && this.allowStatuses.includes(f.status));
	}
	reportOrderCancelGet = async (areaId, dayShiftId) => {
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => f.status === orderStatus.CANCEL);
	}
	reportOrderRefundGet = async (areaId, dayShiftId) => {
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => f.status === orderStatus.REFUND);
	}
	reportOrderOldGet = async () => await Orders.reports.ondatenow();
	reportOtherProductsGet = async () => {
		const start = todayGet(), end = start + 86400;
		return await Transactions.getOthers(start, end);
	}
	report = async () => {
		const {activearea} = this.state;
		const ds = await this.orderDayShiftSet();
		if (ds === null) return;
		const ordersOldData = await this.reportOrderOldGet();
		const ordersOld = ordersOldData.data.filter(f => f.areaId === activearea.id);
		const ordersAll = [...await this.reportOrdersGet(activearea.id, ds.id), ...ordersOld];
		const ordersOnline = ordersAll.filter(f => (f.target === targetType.WEBSITE || f.target === targetType.IOS || f.target === targetType.ANDROID || f.target === targetType.VK || f.target === targetType.ROBOT || f.target === targetType.KIOSK) && f.type === orderType.CLIENT && f.isQR === 0);
		const ordersOnlinePaid = ordersOnline.filter(f => f.isPaid === orderPaid.PAID);
		const ordersOnlinePaidPickup = ordersOnlinePaid.filter(f => f.isPickup === orderPickup.PICKUP);
		const ordersOnlinePaidDelivery = ordersOnlinePaid.filter(f => f.isPickup === orderPickup.DELIVERY);
		const ordersOnlinePaidQRDelivery = ordersAll.filter(f => f.isPaid === orderPaid.PAID && f.isQR === 1);
		const ordersOnlineNotPaidDelivery = ordersOnline.filter(f => f.isPaid === orderPaid.NOT_PAID && f.isPickup === orderPickup.DELIVERY);
		const ordersOnlineNotPaidPickup = ordersOnline.filter(f => f.isPaid === orderPaid.NOT_PAID && f.isPickup === orderPickup.PICKUP);
		const ordersOperator = ordersAll.filter(f => f.target === targetType.DISPATCHER && f.type === orderType.DISPATCHER && f.isQR === 0);
		const ordersOperatorDelivery = ordersOperator.filter(f => f.isPickup === orderPickup.DELIVERY);
		const ordersOperatorPickup = ordersOperator.filter(f => f.isPickup === orderPickup.PICKUP);
		const ordersHall = ordersAll.filter(f => f.target === targetType.OPERATOR && f.type === orderType.OPERATOR_HALL);
		const ordersCancel = await this.reportOrderCancelGet(activearea.id, ds.id);
		const ordersRefund = await this.reportOrderRefundGet(activearea.id, ds.id);
		const terminalFinish = await Accountings.report.finish(activearea.id, ds.id);
		const terminalInWork = await Accountings.report.inWork(activearea.id, ds.id);
		const cash = await CashierMoneyReports.getAll(activearea.id, todayGet());
		const kassa = cash && cash.data.length ? cash.data.reduce((a, b) => a + b.amount, 0) : 0;
		const others = await this.reportOtherProductsGet();
		const dailyReport = {
			hall: {
				count:ordersHall.length,
				amount:this.reportSumCalc(ordersHall)
			},
			onlinepaid: {
				count:ordersOnlinePaid.length,
				amount:this.reportSumCalc(ordersOnlinePaid)
			},
			onlinepaidpickup: {
				count:ordersOnlinePaidPickup.length,
				amount:this.reportSumCalc(ordersOnlinePaidPickup)
			},
			onlinepaiddelivery: {
				count:ordersOnlinePaidDelivery.length,
				amount:this.reportSumCalc(ordersOnlinePaidDelivery)
			},
			onlineqrdelivery: {
				count:ordersOnlinePaidQRDelivery.length,
				amount:this.reportSumCalc(ordersOnlinePaidQRDelivery)
			},
			onlinecashdelivery: {
				count:ordersOnlineNotPaidDelivery.length,
				amount:this.reportSumCalc(ordersOnlineNotPaidDelivery)
			},
			onlinecashpickup: {
				count:ordersOnlineNotPaidPickup.length,
				amount:this.reportSumCalc(ordersOnlineNotPaidPickup)
			},
			operatordelivery: {
				count:ordersOperatorDelivery.length,
				amount:this.reportSumCalc(ordersOperatorDelivery)
			},
			operatorpickup: {
				count:ordersOperatorPickup.length,
				amount:this.reportSumCalc(ordersOperatorPickup)
			},
			old: {
				count:ordersOld.length,
				amount:this.reportSumCalc(ordersOld)
			},
			cancel: {
				count:ordersCancel.length,
				amount:this.reportSumCalc(ordersCancel)
			},
			refund: {
				count:ordersRefund.length,
				amount:this.reportSumCalc(ordersRefund)
			},
			terminal: {
				count:0,
				amount:terminalFinish.data
			},
			terminalInWork: {
				count:0,
				amount:terminalInWork.data
			},
			kassa: {
				count:0,
				amount:kassa
			},
			others: {
				count:others.data.length,
				amount:this.reportOtherSumCalc(others.data)
			},
			all: {
				count:ordersAll.length,
				amount:this.reportSumCalc(ordersAll)
			}
		};
		dailyReport.area = activearea.name;
		Printer.report.report(dailyReport);
		this.setState({modalshowreport:true,dailyReport});
	}
	reportSumCalc = (data) => data.length > 0 ? data.reduce((a, b) => a + b.priceFull, 0) : 0;
	reportOtherSumCalc = (data) => data.length > 0 ? data.reduce((a, b) => a + b.amount, 0) : 0;
	checkPrint = async () => {
		const {user,inn,data} = this.state;
		let {orderproducts} = this.state;
		orderproducts = orderproducts && orderproducts.length ? orderproducts : data.orderproducts;
		if (data.isPrinted) {
			alertShow('Ошибка! Фискальный чек уже был напечатан!', true);
			return;
		}
		const printed = {isPrinted:1};
		if (data.isPickup === orderPickup.PICKUP) {
			const statuses = {
				orderId:data.id,
				userId:user.id,
				userName:user.name,
				status:orderStatus.ARCHIVE
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			printed.status = orderStatus.ARCHIVE;
		}
		Orders.update(data.id, printed, () => Events.add(user.id, Texts.order.update, JSON.stringify({isPrinted:0}), JSON.stringify(printed), data.id, eventType.ORDER));
		this.setState({data});
		Printer.check(user.name, inn, data, orderproducts, true);
		setTimeout(() => {
			this.orderCreateClose();
			this.ordersPanelsUpdate();
		}, 1000);
		try {
			Refusers.addOrder(data);
		}
		catch (err) {
			console.log(666, 'Refusers.addOrder', err);
		}
	}
	cookPrint = async () => {
		const {data} = this.state;
		let {orderproducts} = this.state;
		orderproducts = orderproducts && orderproducts.length ? orderproducts : data.orderproducts;
		Printer.cook(data, orderproducts);
		Printer.collect(data, orderproducts);
	}
	operatorPrint = async () => {
		const {data,user,inn} = this.state;
		let {orderproducts} = this.state;
		orderproducts = orderproducts && orderproducts.length ? orderproducts : data.orderproducts;
		Printer.check(user.name, inn, data, orderproducts, false);
	}
	orderLogist = () => this.setState({modalshowpromptlogist:true});
	orderCancel = () => this.setState({modalshowpromptcancel:true});
	orderCancelSave = () => {
		const {user,data} = this.state, status = orderStatus.CANCEL, order = {status,commentSystem:'Отмена оператором'};
		Orders.update(data.id, order, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:data.status}), JSON.stringify(order), data.id, eventType.ORDER);
			const statuses = {
				orderId:data.id,
				userId:user.id,
				userName:user.name,
				status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		});
		this.orderCreateClose();
		this.ordersPanelsUpdate();
		alertShow('Заказ отменен')
	}
	orderLogistSave = () => {
		const {user,data} = this.state, status = orderStatus.LOGIST_WAIT, order = {status};
		Orders.update(data.id, order, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:data.status}), JSON.stringify(order), data.id, eventType.ORDER);
			const statuses = {
				orderId:data.id,
				userId:user.id,
				userName:user.name,
				status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		});
		this.orderCreateClose();
		this.ordersPanelsUpdate();
		alertShow('Заказ передан логисту')
	}
	dateOrdersShow = () => this.setState({isordersdate:true});
	dateOrdersHide = () => this.setState({isordersdate:false,isordersearchinfo:false});
	cancelOrdersShow = () => this.setState({isorderscancel:true});
	cancelOrdersHide = () => this.setState({isorderscancel:false,isordersearchinfo:false});
	orderAdd = () => this.setState({isfullscreen:true,isnewcall:true,isdelivery:true,orderaddstep:1});
	orderAddInHall = () => this.setState({isfullscreen:true,isnewcall:true,isdelivery:false,orderaddstep:4});
	addressAdd = () => this.setState({clientAddress:'',clientAddressId:0,clientComment:'',addressfull:null,addressClient:'',room:'',entrance:'',intercom:'',floor:'',status:comonStatus.ACTIVE});
	addressChange = () => this.setState({orderaddstep:3});
	orderChange = () => this.setState({orderaddstep:4});
	orderSearch = () => this.setState({isordersearch:true,isordersearchinfo:false});
	orderSearchHide = () => this.setState({isordersearch:false,isordersearchinfo:false,orderssearch:[]});
	handleOrderSeacrh = (value) => {
		if (value.length < 2) {
			this.setState({orderssearch:[]});
			return;
		}
		Orders.find({text:value}, (data) => this.setState({orderssearch:data}));
	}
	orderSearchInfoShow = async (order, isdate) => {
		const products = await OrderItems.get(order.id);
		order.orderproducts = products.data;
		this.setState({data:order,isordersearchinfo:true,isdate});
	}
	orderSearchInfoHide = () => this.setState({isordersearchinfo:false,data:null});
	calc = () => this.setState({modalshowpromptcalc:true,moneyCash:undefined,moneyPOS:undefined,moneyCashFocus:false,moneyPOSFocus:false});
	moneyCashFocus = () => this.setState({moneyCashFocus:true,moneyPOSFocus:false});
	moneyPOSFocus = () => this.setState({moneyCashFocus:false,moneyPOSFocus:true});
	numberSet = (num) => {
		if (this.state.moneyCashFocus) {
			if (num === undefined) this.setState({moneyCash:this.state.moneyCash?this.state.moneyCash.slice(0,-1):this.state.moneyCash});
			else {
				const moneyCash = `${this.state.moneyCash===undefined?'':this.state.moneyCash}${num}`;
				if (moneyCash.length < 8) this.setState({moneyCash});
			}
		}
		if (this.state.moneyPOSFocus) {
			if (num === undefined) this.setState({moneyPOS:this.state.moneyPOS.slice(0,-1)});
			else {
				const moneyPOS = `${this.state.moneyPOS===undefined?'':this.state.moneyPOS}${num}`;
				if (moneyPOS.length < 8) this.setState({moneyPOS});
			}
		}
	}
	calcTotalGet = () => {
		const moneyCash = isNaN(this.state.moneyCash) ? 0 : parseFloat(this.state.moneyCash),
			moneyPOS = isNaN(this.state.moneyPOS) ? 0 : parseFloat(this.state.moneyPOS);
		return moneyCash + moneyPOS;
	}
	calcReport = async () => {
		const {activearea} = this.state;
		const ds = await this.orderDayShiftSet();
		if (ds === null) return;
		const ordersAll = await this.reportOrdersGet(activearea.id, ds.id);
		const ordersOldData = await this.reportOrderOldGet();
		const ordersOld = ordersOldData.data.filter(f => f.areaId === activearea.id);
		const ordersOnline = ordersAll.filter(f => (f.target === targetType.WEBSITE || f.target === targetType.IOS || f.target === targetType.ANDROID || f.target === targetType.VK || f.target === targetType.ROBOT || f.target === targetType.KIOSK) && f.type === orderType.CLIENT && f.isQR === 0);
		const ordersQR = ordersAll.filter(f => f.isPaid === orderPaid.PAID && f.isQR === 1)
		const moneyTotal = {
			all:this.reportSumCalc(ordersAll) + this.reportSumCalc(ordersOld),
			online:this.reportSumCalc(ordersOnline) + this.reportSumCalc(ordersQR),
			old:this.reportSumCalc(ordersOld),
			cash:isNaN(this.state.moneyCash) ? 0 : parseFloat(this.state.moneyCash),
			terminal:isNaN(this.state.moneyPOS) ? 0 : parseFloat(this.state.moneyPOS),
		};
		moneyTotal.result = moneyTotal.all - moneyTotal.online - moneyTotal.cash - moneyTotal.terminal;
		this.setState({moneyTotal});
		Printer.report.calc(moneyTotal);
	}
	logistSwitch = () => {
		const {user} = this.state;
		user.type = userType.LOGIST;
		ls('user', user);
		ls('isswitch', true);
		window.location.reload(false);
	}
	whareHouseShow = () => {
		this.setState({iswharehouse:true,isfullscreen:true});
		const {activearea} = this.state;
		this.storeGet(activearea.id);
	}
	whareHouseHide = () => this.setState({iswharehouse:false,isfullscreen:false,iswharehouserequest:false,store:null,requestrequestids:this.state.requestids,isrequestsend:false});
	whareHouseRequestShow = () => this.setState({iswharehouserequest:true});
	whareHouseRequestHide = () => this.setState({iswharehouserequest:false,requestrequestids:this.state.requestids,isrequestsend:false});
	whareHouseAlertsDownload = () => {
		const {store} = this.state, d = {n:store.name,i:[]};
		this.state.store.items.forEach((v) => d.i.push({n:v.cardId?v.cardName:v.ingredientName,t:measureTypeName[v.measureType],m:v.min,q:v.quantity.toFixed(3)}));
		this.whareHouseDownload(d, 'alerts');
	}
	whareHouseRequestDownload = () => {
		const {requestrequestids,store} = this.state, d = {n:store.name,i:[]};
		store.items.forEach((v) => {
			if (requestrequestids.includes(v.id)) {
				d.i.push({
					n:v.cardId?v.cardName:v.ingredientName,
					t:measureTypeName[v.measureType],
					p:moneyFormat(v.price),
					q:((v.quantity < 0 ? Math.abs(v.quantity) : 0) + v.defaultCount).toFixed(3)
				});
			}
		});
		this.whareHouseDownload(d, 'request');
	}
	whareHouseDownload = (data, type) => window.open(SITE.docs(base64(data), type), '_blank');
	whareHouseRequestAdd = async () => {
		const {requestrequestids,user,store} = this.state;
		if (requestrequestids.length === 0) {
			alertShow('Для создания завяки нужно выбрать минимум одну позицию!', true);
			return;
		}
		const data = {
			storeId:store.id,
			userId:user.id,
			userName:user.name,
			type:requestType.AUTO,
			status:requestStatus.CREATE
		};
		Stores.requestAdd(data, (res) => {
			const id = res.data.id;
			Events.add(user.id, Texts.request.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.STORE_REQUEST);
			store.items.forEach(async (v) => {
				if (requestrequestids.includes(v.id)) {
					const item = {
						requestId:id,
						measureType:v.measureType,
						price:v.price * 100,
						quantity:(v.quantity < 0 ? Math.abs(v.quantity) : 0) + v.defaultCount
					};
					if (v.cardId) {
						item.extCardId = v.cardId;
						item.extCardName = v.cardName;
					} else {
						item.ingredientId = v.ingredientId;
						item.ingredientName = v.ingredientName;
					}
					await Stores.requestItemAdd(item);
				}
			});
			this.setState({isrequestsend:true});
		}, (err) => {
			Logs.add(err);
		});
		alertShow('Заявка успешно отправлена!');
	}
	handleFormStore = (e) => {
		const id = parseInt(e.target.value);
		if (e.target.checked) this.setState({requestrequestids:[...this.state.requestrequestids,id]});
		else {
			const requestrequestids = [];
			this.state.requestrequestids.forEach((v) => {
				if (id !== v) requestrequestids.push(v);
			});
			this.setState({requestrequestids});
		}
	}
	cashInShow = (dayshift) => {
		const cashin = ls('cashin');
		if (cashin) {
			if (cashin.areaId === dayshift.areaId && cashin.dayShiftId === dayshift.id) return;
		}
		this.setState({modalshowcashin:true});
	}
	cashPaperSet = (cashPaper) => this.setState({cashPaper});
	cashCoinSet = (cashCoin) => this.setState({cashCoin});
	cashOpen = () => {
		const {user,dayshift,cashPaper,cashCoin} = this.state;
		const data = {
			areaId:dayshift.areaId,
			dayShiftId:dayshift.id,
			userId:user.id,
			userName:user.name,
			cashPaper:cashPaper,
			cashCoin:cashCoin,
			type:dayShiftCashType.OPERATOR
		}
		DayShiftCashes.add(data);
		ls('cashin', {areaId:dayshift.areaId,dayShiftId:dayshift.id,isopen:true});
		this.setState({modalshowcashin:false});
	}
	cashOutShow = async () => {
		const cashin = ls('cashin');
		if (cashin && !cashin.isopen) {
			alertShow('Внимание! Вы уже закрыли кассу!', true);
			return;
		}
		const {activearea,amountTerminal} = this.state;
		const ds = await this.orderDayShiftSet();
		if (ds === null) return;
		const exchange = await DayShiftCashes.get(activearea.id, ds.id);
		if (exchange === null) return;
		const cashPaper = exchange.data[0].cashPaper;
		const cashCoin = exchange.data[0].cashCoin;
		const ordersOldData = await this.reportOrderOldGet();
		const ordersOld = ordersOldData.data.filter(f => f.areaId === activearea.id);
		const ordersAll = [...await this.reportOrdersGet(activearea.id, ds.id),...ordersOld];
		const ordersOnline = ordersAll.filter(f => (f.target === targetType.WEBSITE || f.target === targetType.IOS || f.target === targetType.ANDROID || f.target === targetType.VK || f.target === targetType.ROBOT || f.target === targetType.KIOSK) && f.type === orderType.CLIENT && f.isQR === 0);
		const ordersHall = ordersAll.filter(f => f.target === targetType.OPERATOR && f.type === orderType.OPERATOR_HALL);
		const ordersOnlineNotPaidPickup = ordersOnline.filter(f => f.isPaid === orderPaid.NOT_PAID && f.isPickup === orderPickup.PICKUP);
		const ordersOperator = ordersAll.filter(f => f.target === targetType.DISPATCHER && f.type === orderType.DISPATCHER && f.isQR === 0);
		const ordersOperatorPickup = ordersOperator.filter(f => f.isPickup === orderPickup.PICKUP);
		const cash = await CashierMoneyReports.getAll(activearea.id, ds.id);
		const kassa = cash && cash.data.length ? cash.data.reduce((a, b) => a + b.amount, 0) : 0;
		const order = this.reportSumCalc(ordersHall) + this.reportSumCalc(ordersOperatorPickup) + this.reportSumCalc(ordersOnlineNotPaidPickup);
		const logist = await MoneyTransfers.get(activearea.id, ds.id, moneyTransferType.LOGIST_CASHER);
		const logistCasher = logist.data.length > 0 ? logist.data.reduce((a, b) => a + b.amount, 0) : 0;
		const logist2 = await MoneyTransfers.get(activearea.id, ds.id, moneyTransferType.CASHIER_LOGIST);
		const casherLogist = logist2.data.length > 0 ? logist2.data.reduce((a, b) => a + b.amount, 0) : 0;
		const casherExchange = parseFloat(cashPaper + cashCoin);
		const casherFinal = order + casherExchange + logistCasher - casherLogist - (amountTerminal ? parseFloat(amountTerminal) : 0);
		this.setState({casherOrders:order,logistCasher,casherLogist,casherExchange,casherFinal,cashCoin,cashPaper,modalshowcashout:true});
	}
	cashOutCalc = () => {
		const {casherOrders,logistCasher,casherLogist,casherExchange,amountTerminal} = this.state;
		const casherFinal = casherOrders + casherExchange + logistCasher - casherLogist - (amountTerminal ? parseFloat(amountTerminal) : 0);
		this.setState({casherFinal});
	}
	cashOutSave = () => {
		const {user,dayshift,logistCasher,casherOrders,cashCoin,cashPaper,amountTerminal,casherFinal} = this.state;
		const cashin = ls('cashin');
		const data = {
			areaId:dayshift.areaId,
			dayShiftId:dayshift.id,
			userId:user.id,
			userName:user.name,
			cashOrders:casherOrders,
			cashCard:amountTerminal ? parseFloat(amountTerminal) : 0,
			cashLogist:logistCasher,
			cashExchange:cashPaper + cashCoin,
			amount:casherFinal
		};
		DayShiftCashReports.add(data)
		ls('cashin', {...cashin,isopen:false});
		this.setState({modalshowcashout:false});
		alertShow('Касса закрыта!');
		Printer.report.cashClose(data);
	}
	numberTerminalSet = (num) => {
		if (this.state.amountTerminalFocus) {
			if (num === undefined) this.setState({amountTerminal:this.state.amountTerminal?this.state.amountTerminal.slice(0,-1):this.state.amountTerminal}, this.cashOutCalc);
			else {
				const amountTerminal = `${this.state.amountTerminal===undefined?'':this.state.amountTerminal}${num}`;
				if (amountTerminal.length < 8) this.setState({amountTerminal}, this.cashOutCalc);
			}
		}
	}
	showToKassa = (modalshowtokassa) => this.setState({modalshowtokassa});
	numberTransferSet = (num) => {
		if (this.state.amountTransferFocus) {
			if (num === undefined) this.setState({amountTransfer:this.state.amountTransfer?this.state.amountTransfer.slice(0,-1):this.state.amountTransfer});
			else {
				const amountTransfer = `${this.state.amountTransfer===undefined?'':this.state.amountTransfer}${num}`;
				if (amountTransfer.length < 8) this.setState({amountTransfer});
			}
		}
	}
	amountTransferFocus = () => this.setState({amountTransferFocus:true});
	moneyTransfer = () => {
		const {amountTransfer,user,dayshift} = this.state;
		const data = {
			areaId:dayshift.areaId,
			dayShiftId:dayshift.id,
			userId:user.id,
			userName:user.name,
			amount:amountTransfer,
			type:moneyTransferType.CASHIER_LOGIST
		};
		MoneyTransfers.add(data);
		this.showToKassa(false)
		this.setState({amountTransfer:''});
		alertShow('Средства переданы логисту!');
	}
	amountTerminalFocus = () => this.setState({amountTerminalFocus:true});
	adminShow = (isadmin) => this.setState({isadmin});
	pinCodeShow = () => this.setState({modalshowpin:true,pinFocus:true});
	passwordSet = (num) => {
		if (num === '.') return;
		if (this.state.pinFocus) {
			if (num === undefined) this.setState({pin:this.state.pin?this.state.pin.slice(0,-1):this.state.pin});
			else {
				const pin = `${this.state.pin===undefined?'':this.state.pin}${num}`;
				if (pin.length < 5) this.setState({pin});
			}
		}
	}
	pinFocus = () => this.setState({pinFocus:true});
	pinCodeCheck = () => {
		const {pin} = this.state;
		if (PIN_CODE.includes(pin)) {
			this.setState({pin:undefined,pinFocus:false});
			this.adminShow(true);
			this.modalHide();
		} else alertShow('ПИН-код введен непраивльно!', true);
	}
	quantityGroupGet = (items) => {
		const orderitems = [];
		items.forEach((v,i) => {
			const item = orderitems.find(f => f.id === v.id);
			if (item) item.quantity++;
			else orderitems.push({...v,quantity:1});
		});
		return orderitems;
	}
	modalShow = () => this.setState({modalshow:true});
	modalHide = () => this.setState({modalshowprompt:false,modalshowpromptclose:false,modalshowpromptpaid:false,modalshowpromptpaidhall:false,modalshowpromptcalc:false,modalshowpromptcancel:false,modalshowpromptlogist:false,modalshowpromptdayshiftclose:false,modalshowpromptdayshiftclosefinish:false,modalshowreport:false,modalshowsectionsreport:false,modalshowpin:false,modalshowcashin:false,modalshowcashout:false,moneyTotal:null,pin:undefined,sectionsreport:null});
	successMessageShow = () => alertShow(Texts.success.simple);
	sectionsreport = async () => {
		const {activearea} = this.state;
		const ds = await this.orderDayShiftSet();
		if (ds === null) return;
		const d = await Orders.stats.products(activearea.id, ds.id);
		const data = d.data;
		const old = await Orders.stats.productsoldnow(activearea.id);
		data.forEach((v) => {
			const item = old.data.find(f => f.productId === v.productId);
			if (item) v.count = v.count + item.count;
		});
		old.data.forEach((v) => {
			const item = data.find(f => f.productId === v.productId);
			if (item === undefined) data.push(v);
		});
		const group = data.reduce((section, item) => {
			const {sectionId} = item;
			section[sectionId] = section[sectionId] ?? [];
			section[sectionId].push(item);
			return section;
		}, {});
		const sectionsreport = [];
		Object.entries(group).forEach((v) => {
			sectionsreport.push({
				name:v[1][0].sectionName,
				count:v[1].reduce((a, v) => a + (v.count), 0),
				amount:v[1].reduce((a, v) => a + (v.priceDiscount||v.price)*v.count, 0)
			});
		});
		this.setState({modalshowsectionsreport:true,sectionsreport});
	}
	sectionsReportPrint = () => Printer.report.sections(this.state.sectionsreport);
	viewSwitch = () => this.setState({isall:!this.state.isall});
	render() {
		if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
		if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
		if (this.state.iserrordayshift) return <div className={styles.main}><Error refresh={this.dataGet} title={'Невозможно принять заказ'} message={'Смена не открыта! Обратитесь к вашему управляющему для открытия смены'} /></div>;
		return <div className={styles.container}>
			<div className={styles.areaactive}>
				<button>
					<img src={require('../../../Images/icons/marker_black.svg')} alt="" />
					{this.state.activearea.name}
				</button>
			</div>
			<div className={styles.main}>
				{this.state.isfullscreen ?
					(this.state.isnewcall ?
						<>
							<div className={styles.ordercallcontainer}>
								<div className={styles.header}>
									<h4>{this.stepNameGet(this.state.orderaddstep)}</h4>
									<Close close={this.orderClose.bind(this)} />
								</div>
								<Scroller className={cx(styles.orderbody,this.state.orderaddstep===1?styles.orderbodyclient:null)}>
									<>
									{this.state.orderaddstep === 1 && <div className={cx(styles.clientcontainer,styles.formcontainer)}>
											<div className={styles.phoneblock}>
												<Keyboard type={'text'} placeholder={'Номер телефона клиента'} className={styles.keyboard} autoFocus={true} onChange={this.handleClient} />
												<button className={styles.button} onClick={() => this.clientAdd()}>
													<img src={require('../../../Images/icons/add_white.svg')} alt="" />
													Новый клиент
												</button>
											</div>
											{this.state.showclientsearch &&
												(this.state.clients === null ? null :
													(this.state.clients.length === 0 ?
														<NotFound message={<>
															<h4>Клиент не наден!</h4>
															<p>Смягчите условия поиска или добавьте нового клиента</p>
														</>} />
														:
														<div className={styles.addresseslist}>
															{this.state.clients.map((v, i) => <div key={i} className={styles.container}>
																<div>
																	<b>{phoneFormatter(v.phone)}</b>
																	<span>{v.name ? `${v.name}, `: ''}{this.clientCurrentAddressGet(v.addresses)}</span>
																</div>
																<div className={styles.button} onClick={() => this.searchClientSelect(v)}>Выбрать</div>
															</div>)}
														</div>
													)
												)
											}
										</div>}
									{this.state.orderaddstep === 2 && <div className={cx(styles.clientcontainer,styles.formcontainer)}>
											<div className={styles.block}>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<Keyboard type={'text'} placeholder={'Телефон'} className={styles.keyboardphone} autoFocus={true} onChange={this.handlePhone} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Имя</span>
														<input type="text" name="clientName" placeholder="Имя" onChange={this.handleForm} />
													</label>
												</div>
											</div>
										</div>}
									{this.state.orderaddstep === 3 && <div className={cx(styles.addresscontainer,styles.formcontainer)}>
											<div className={styles.addressblock}>
												<Keyboard type={'text'} placeholder={'Введите адрес доставки'} value={this.state.addressClient} className={styles.keyboardaddress} autoFocus={true}onChange={this.handleStreetAddress} />
												<button className={styles.button} onClick={() => this.handleStreet()}>
													<img src={require('../../../Images/icons/search_white.svg')} alt="" />
													Найти
												</button>
											</div>
											{this.state.showaddresssearch ?
													<div className={styles.addresseslist}>
														{this.state.addresses.map((v, i) => <div key={i} className={styles.container}>
																<div>
																	<b>{v.name}</b>
																	<span>{v.description}</span>
																</div>
																<div className={styles.button} onClick={() => this.searchAddressSelect(v)}>Выбрать</div>
															</div>)}
													</div>
												: null}
											{this.state.showaddresssearch ? null :
												<div className={styles.inputs}>
													<div className={styles.row}>
														<label>
															Комната/Офис
															<Keyboard type={'text'} placeholder={'Комната/Офис'} value={this.state.room} className={styles.keyboardaddress} autoHide={true} onChange={this.handleAddressRoom} />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Подъезд
															<Keyboard type={'text'} placeholder={'Подъезд'} value={this.state.entrance} className={styles.keyboardaddress} autoHide={true} onChange={this.handleAddressEntrance} />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Этаж
															<Keyboard type={'text'} placeholder={'Этаж'} value={this.state.floor} className={styles.keyboardaddress} autoHide={true} onChange={this.handleAddressFloor} />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Домофон
															<Keyboard type={'text'} placeholder={'Домофон'} value={this.state.intercom} className={styles.keyboardaddress} autoHide={true} onChange={this.handleAddressIntercom} />
														</label>
													</div>
												</div>}
										</div>}
									{this.state.orderaddstep === 4 && <>
											{this.state.sectionId === 0 && this.state.categoryId === 0 ?
												<div className={styles.sections}>
													{this.state.sections.map((v,i) => <div key={i} className={cx(styles.section,styles[`${v.link}`])} onClick={() => this.sectionSelect(v.id, v.name, v.link)}>
															<img src={require(`../../../Images/icons/sections/${v.link}.svg`)} alt={v.name} />
															<div>{v.name}</div>
														</div>)}
												</div>
											:
												this.itemsShow()}
										</>}
									{this.state.orderaddstep === 5 && <div className={cx(styles.orderadditionals,styles.formcontainer)}>
											<div className={styles.block}>
												{/*
												<div className={styles.cell}>
													<label>
														<span>Персональная скидка, %</span>
														<input type="text" name="manualDiscountPercent" maxLength={2} value={this.state.manualDiscountPercent} onChange={(e) => this.handleDiscountForm(e, 50)} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Персональная скидка, ₽</span>
														<input type="text" name="manualDiscount" maxLength={4} value={this.state.manualDiscount} onChange={(e) => this.handleDiscountForm(e, 1000)} />
													</label>
												</div>
												*/}
												<div className={styles.cell}>
													<label>
														<span>Промокод</span>
														<Keyboard type={'text'} placeholder={'Промокод'} className={styles.keyboardpromo} value={this.state.promoCode} onChange={this.handlePromoCodeForm} />
													</label>
												</div>
											</div>
											{this.state.isdelivery ?
												<>
													{/*
													<div className={styles.commentblock}>
														<div className={styles.cell}>
															<label>
																<span>Комментарий</span>
																<input type="text" name="comment" value={this.state.clientComment} placeholder="Комментарий к заказу" onChange={this.handleCommentForm} />
															</label>
														</div>
													</div>
													<div className={styles.block}>
														<div className={styles.cell}>
															<label>
																<span>Цена доставки</span>
																<input type="text" name="deliveryCostManual" maxLength={3} value={this.state.deliveryCostManual} onChange={this.handleDeliveryForm} />
															</label>
														</div>
														<div className={styles.cell}>
															<label>
																<span>Сдача с, ₽</span>
																<input type="text" name="surrender" maxLength={5} value={this.state.surrender} onChange={(e) => this.handleDiscountForm(e, 50000, true)} />
															</label>
														</div>
														<div className={styles.cell}>
															<label>
																<span>Количество приборов</span>
																<input type="text" name="cutlery" maxLength={1} value={this.state.cutlery} onChange={(e) => this.handleDiscountForm(e, 10, true)} />
															</label>
														</div>
													</div>
													*/}
													<div className={cx(styles.block,styles.blockdate)}>
														<div className={styles.cell}>
															<label>
																<span>Заказ на дату и время</span>
																<div>
																	<input type="datetime-local" name="date" value={this.state.date} onChange={this.handleDateForm} />
																	<img src={require('../../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateClear()} />
																</div>
															</label>
														</div>
													</div>
												</> : null}
										</div>}
									</>
								</Scroller>
								{this.state.orderaddstep !== 1 ?
									<div className={styles.controlpanel}>
										<button className={cx(styles.button,
											this.state.orderaddstep === 2 && phoneNormalize(this.state.clientPhone) === null ? styles.disabled :
												(this.state.orderaddstep === 4 && this.state.orderitems.length === 0 ? styles.disabled : null)
											)} onClick={() => this.orderNext()}>
											{this.state.orderaddstep === 5 ? 
													<>
														<img src={require('../../../Images/icons/mark.svg')} alt="Создать заказ" />
														Создать заказ
													</>
												:
													<>
														Далее
														<img src={require('../../../Images/icons/next.svg')} className={styles.right} alt="Далее" />
													</>
											}
											</button>
											<div>
												{this.state.isdelivery && (this.state.orderaddstep === 4 || this.state.orderaddstep === 5) ?
													<button className={cx(styles.button,styles.secondary)} onClick={() => this.addressChange()}>
														<img src={require('../../../Images/icons/marker_black.svg')} alt="Изменить адрес" />
														Изменить адрес
													</button>
												: null}
												{this.state.orderaddstep === 5 ?
													<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderChange()}>
														<img src={require('../../../Images/icons/order.svg')} alt="Изменить заказ" />
														Изменить заказ
													</button>
												: null}
											</div>
									</div> : null}
							</div>
							<div className={styles.column}>
								{this.state.orderaddstep === 1 || this.state.orderaddstep === 2 ?
									<>
										<div className={cx(styles.header,styles.info)}>
											Информация о клиенте
											<img src={require('../../../Images/icons/statuses/user_search.svg')} alt="" />
										</div>
										<div className={cx(styles.container,styles.containerinfo)}>
											<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>—</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<div>—</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Адрес доставки</span>
														<div>—</div>
													</label>
												</div>
											</div>
										</div>
									</>
								: null}
								{this.state.orderaddstep === 3 ?
									<>
										<div className={cx(styles.header,styles.info)}>
											Информация о клиенте
											<img src={require('../../../Images/icons/statuses/user.svg')} alt="" />
										</div>
										<div className={cx(styles.container,styles.containerinfo)}>
											<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>{this.state.client ? (this.state.client.name || '—') : 'новый клиент'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<div>{this.state.clientPhone ? phoneFormatter(this.state.clientPhone) : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Адрес доставки</span>
														{this.state.client && this.state.client.addresses.length ?
															<select onChange={this.handleAddressSelect} value={this.state.clientAddressId}>
																<option>Новый адрес</option>
																{this.state.client.addresses.map((v,i) => <option key={i} value={v.id}>{v.address}</option>)}
															</select> : <div>—</div>}
													</label>
												</div>
												{this.state.client && this.state.client.addresses.length ?
													<div className={styles.cell}>
														<label>
															<span>Комментарий</span>
															<div>{this.state.clientComment || '—'}</div>
														</label>
													</div>
													: null}
											</div>
										</div>
									</>
								: null}
								{this.state.orderaddstep === 4 || this.state.orderaddstep === 5 ?
									<>
										<div className={cx(styles.header,styles.info,styles.order)}>
											Позиции заказа
											<img src={require('../../../Images/icons/statuses/order.svg')} alt="" />
										</div>
										<div className={cx(styles.item,styles.itemwide,styles.productinfo)}>
											{this.state.orderitems.length ?
												<>
													<Scroller className={styles.orderlist}>
														{this.state.orderaddstep === 5 ?
															this.quantityGroupGet(this.state.orderitems).map((v,i) => <div key={i} className={styles.row}>
																<div className={styles.name}>
																	<div>{i+1}. {v.name||v.productName}</div>
																	<div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
																</div>
																<div className={styles.quantitytext}>x {quantityFormat(v.quantity||1)}</div>
																<div className={styles.price}>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, true)} ₽</div>
																{this.state.orderaddstep === 4 && <div className={styles.delete} onClick={() => this.productRemove(i)}>
																		<img src={require('../../../Images/icons/close_black.svg')} alt="Удалить" />
																	</div>}
															</div>)
														:
															this.state.orderitems.map((v,i) => <div key={i} className={styles.row}>
																<div className={styles.name}>
																	<div>{i+1}. {v.name||v.productName}</div>
																	<div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
																</div>
																<div className={styles.price}>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, true)} ₽</div>
																{this.state.orderaddstep === 4 && <div className={styles.delete} onClick={() => this.productRemove(i)}>
																		<img src={require('../../../Images/icons/close_black.svg')} alt="Удалить" />
																	</div>}
															</div>)
														}
													</Scroller>
													<div className={styles.summary}>
														<div className={styles.row}><span>Сумма заказа</span><span><b>{this.state.price} ₽</b></span></div>
														<div className={styles.row}><span>Сумма со скидкой:</span><span>{this.state.priceDiscount} ₽</span></div>
														<div className={styles.row}><span>Сумма к оплате:</span><span><b>{this.priceFullGet()} ₽</b></span></div>
														<div className={styles.row}><span>Скидка</span><span>{this.state.discount ? `${this.state.discount} ₽` : 'нет'}</span></div>
														{this.state.discountName && <div className={styles.hint} title={this.state.discountName}>{this.state.discountName}</div>}
														{this.state.isdelivery ?
																<div className={styles.row}><span>Доставка</span><span>Самовывоз</span></div>
															:
																<>
																	<div className={styles.row}><span>Доставка</span><span>{this.state.deliveryCostManual !== undefined ? this.state.deliveryCostManual : (this.state.price >= this.state.deliveryFreeAmount ? '0': this.state.deliveryCost)} ₽</span></div>
																	<div className={styles.row}><span>Бесплатная доставка от</span><span>{this.state.deliveryFreeAmount} ₽</span></div>
																</>
														}
													</div>
												</>
											:
												<div>Выберите блюда и добавьте их к заказу</div>
											}
										</div>
									</>
								: null}
							</div>
						</> : null)
					:
						this.state.isordersearch ?
							(this.state.isordersearchinfo ?
									<div className={cx(styles.ordersearchcontainer,styles.orderinfocontainer)}>
										<div className={styles.header}>
											<h4>Состав заказа №{this.state.data.id}</h4>
											<Close close={this.orderSearchInfoHide.bind(this)} />
										</div>
										<Scroller className={cx(styles.orderbody,styles.orderbodysearch)}>
											<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
										</Scroller>
										<div className={styles.controlpanel}>
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.operatorPrint()}>
													<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
													Оператору
												</button>
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.cookPrint()}>
													<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
													На кухню
												</button>
												{this.state.data.isPrinted ?
														<button className={cx(styles.button,styles.secondary)}>
															<img src={require('../../../Images/icons/print_error.svg')} alt="Чек в архиве" />
															{/* Чек в архиве */}
															Уже напечатан
														</button>
													:
														<button className={cx(styles.button,styles.secondary)} onClick={() => this.checkPrint()}>
															<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
															Фиск.чек
														</button>
												}
											</div>
									</div>
								:
									<div className={styles.ordersearchcontainer}>
										<div className={styles.header}>
											<h4>Поиск заказа</h4>
											<Close close={this.orderSearchHide.bind(this)} />
										</div>
										<div className={styles.orderbody}>
											<div className={styles.formcontainer}>
												<Keyboard type={'text'} placeholder={'Поиск заказа по номеру, адресу доставки, телефону или имени клиента'} autoFocus={true} onChange={this.handleOrderSeacrh} autoSend={false} />
											</div>
											<Scroller className={styles.orderscontainer}>
												{this.state.orderssearch.map((v,i) => <div key={i} className={styles.orderitem} onClick={() => this.orderSearchInfoShow(v)}>
														<div className={styles.orderiteminner}>
															<div className={styles.number}>Заказ №{v.id}</div>
															<div className={styles.block}>
																<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
																<span className={styles.status}>{orderStatusName[v.status]}</span>
															</div>
															<div className={styles.block}>
																<div><b>{v.address}</b></div>
																<div className={styles.amount}>{this.amountGet(v)}</div>
															</div>
															<div className={styles.products}>
																{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
															</div>
														</div>
													</div>)}
											</Scroller>
										</div>
									</div>
							)
						:
							(this.state.isordershow ?
								<div className={styles.orderinfocontainer}>
									<div className={styles.header}>
										<h4>Состав заказа №{this.state.data.id}</h4>
										<Close close={this.orderShowClose.bind(this)} />
									</div>
									<Scroller className={styles.orderbody}>
										<OrderInfo data={this.state.data} orderproducts={this.state.orderproducts} areas={this.state.areas} />
									</Scroller>
									{![orderStatus.OPERATOR_WAIT].includes(this.state.data.status) ?
										<div className={styles.controlpanel}>
											{!this.state.noOperator ?
												<button className={styles.button} onClick={() => this.orderAccept()}>
													<img src={require('../../../Images/icons/mark.svg')} alt="Принять заказ" />
													Принять заказ
												</button> : null}
											{this.allowSkipStatuses.includes(this.state.data.status) ?
												<button className={cx(styles.button,styles.secondary,styles.logist)} onClick={() => this.orderLogist()}>
													<img src={require('../../../Images/icons/route_white.svg')} alt="Логисту" />
													Логисту
												</button> : null}
											<button className={cx(styles.button,styles.secondary)} onClick={() => this.operatorPrint()}>
												<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
												Оператору
											</button>
											<button className={cx(styles.button,styles.secondary)} onClick={() => this.cookPrint()}>
												<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
												На кухню
											</button>
											{this.state.data.isPrinted ?
													<button className={cx(styles.button,styles.secondary)}>
														<img src={require('../../../Images/icons/print_error.svg')} alt="Чек в архиве" />
														{/* Чек в архиве */}
														Уже напечатан
													</button>
												:
													<button className={cx(styles.button,styles.secondary)} onClick={() => this.checkPrint()}>
														<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
														Фиск.чек
													</button>
											}
										</div> : null}
								</div>
							:
								this.state.isordersdate ?
									(this.state.isordersearchinfo ?
										<div className={cx(styles.ordersearchcontainer,styles.orderinfocontainer)}>
											<div className={styles.header}>
												<h4>Состав заказа №{this.state.data.id}</h4>
												<Close close={this.orderSearchInfoHide.bind(this)} />
											</div>
											<Scroller className={cx(styles.orderbody,styles.orderbodysearch)}>
												<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
											</Scroller>
											<div className={styles.controlpanel}>
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.operatorPrint()}>
													<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
													Оператору
												</button>
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.cookPrint()}>
													<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
													На кухню
												</button>
												{this.state.data.isPrinted ?
														<button className={cx(styles.button,styles.secondary)}>
															<img src={require('../../../Images/icons/print_error.svg')} alt="Чек в архиве" />
															{/* Чек в архиве */}
															Уже напечатан
														</button>
													:
														<button className={cx(styles.button,styles.secondary)} onClick={() => this.checkPrint()}>
															<img src={require('../../../Images/icons/print.svg')} alt="Печатать чек" />
															Фиск.чек
														</button>
												}
											</div>
										</div>
									:
										<div className={styles.ordersearchcontainer}>
											<div className={styles.header}>
												<h4>Заказы на дату</h4>
												<Close close={this.dateOrdersHide.bind(this)} />
											</div>
											<div className={styles.orderbody}>
												<Scroller className={cx(styles.orderscontainer,styles.orderscontainerold)}>
													{this.state.ordersdate.map((v,i) => <div key={i} className={styles.orderitem} onClick={() => this.orderSearchInfoShow(v)}>
															<div className={styles.orderiteminner}>
																<div className={styles.number}>Заказ №{v.id}</div>
																<div className={styles.block}>
																	<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
																	<span className={styles.status}>{orderStatusName[v.status]}</span>
																</div>
																<div className={styles.block}>
																	<div><b>{v.address}</b></div>
																	<div className={styles.amount}>{this.amountGet(v)}</div>
																</div>
																<div className={styles.products}>
																	{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
																</div>
															</div>
														</div>)}
												</Scroller>
											</div>
										</div>)
								:
									(this.state.isorderscancel ?
										(this.state.isordersearchinfo ?
											<div className={cx(styles.ordersearchcontainer,styles.orderinfocontainer)}>
												<div className={styles.header}>
													<h4>Состав заказа №{this.state.data.id}</h4>
													<Close close={this.orderSearchInfoHide.bind(this)} />
												</div>
												<Scroller className={cx(styles.orderbody,styles.orderbodysearch,styles.orderbodycancel)}>
													<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
												</Scroller>
											</div>
										:
											<div className={styles.ordersearchcontainer}>
												<div className={styles.header}>
													<h4>Отмененные заказы</h4>
													<Close close={this.cancelOrdersHide.bind(this)} />
												</div>
												<div className={styles.orderbody}>
													<Scroller className={cx(styles.orderscontainer,styles.orderscontainerold)}>
														{this.state.orderscancel.map((v,i) => <div key={i} className={styles.orderitem} onClick={() => this.orderSearchInfoShow(v)}>
																<div className={styles.orderiteminner}>
																	<div className={styles.number}>Заказ №{v.id}</div>
																	<div className={styles.block}>
																		<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
																		<span className={styles.status}>{orderStatusName[v.status]}</span>
																	</div>
																	<div className={styles.block}>
																		<div><b>{v.address}</b></div>
																		<div className={styles.amount}>{this.amountGet(v)}</div>
																	</div>
																	<div className={styles.products}>
																		{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
																	</div>
																</div>
															</div>)}
													</Scroller>
												</div>
											</div>)
										:
											<>
												{this.state.isadmin ?
													<>
														<div className={styles.column}>
															<div className={cx(styles.button,styles.logist)} onClick={() => this.adminShow(false)}>
																<div>
																	<img src={require('../../../Images/icons/arrow_left_white.svg')} alt="" />
																	Назад
																</div>
															</div>
															{[0].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
															<div className={cx(styles.button,styles.shift)} onClick={() => this.shift(true)}>
																<div>
																	<img src={require('../../../Images/icons/dayshift_open_white.svg')} alt="" />
																	Открыть смену
																</div>
															</div>
															{this.state.dayshiftreload ? 
																	<div className={cx(styles.button,styles.shiftclose)} onClick={() => this.dayShiftReload()}>
																		<div>
																			<img src={require('../../../Images/icons/reload_white.svg')} alt="" />
																			Перегрузить
																		</div>
																	</div>
																:
																	<div className={cx(styles.button,styles.shiftclose)} onClick={() => this.shift(false)}>
																		<div>
																			<img src={require('../../../Images/icons/dayshift_close_white.svg')} alt="" />
																			Закрыть смену
																		</div>
																	</div>
															}
															{[0,1,2,3].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
														</div>
														<div className={styles.column}>
															{[0,1].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
															<div className={cx(styles.button,styles.report)} onClick={() => this.report()}>
																<div>
																	<img src={require('../../../Images/icons/report_white.svg')} alt="" />
																	Суточный отчет
																</div>
															</div>
															<div className={cx(styles.button,styles.calc)} onClick={() => this.calc()}>
																<div>
																	<img src={require('../../../Images/icons/calc_white.svg')} alt="" />
																	Сверка
																</div>
															</div>
															<div className={cx(styles.button,styles.sectionsreport)} onClick={() => this.sectionsreport()}>
																<div>
																	<img src={require('../../../Images/icons/order_white.svg')} alt="" />
																	Отчет по станциям
																</div>
															</div>
															{[0,1,2].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
														</div>
													</>
													:
													<>
														<div className={styles.column}>
															<div className={cx(styles.button,styles.orderaddroom)} onClick={() => this.orderAddInHall()}>
																<div>
																	<img src={require('../../../Images/icons/add_white.svg')} alt="" />
																	Новый заказ в зале
																</div>
															</div>
															<div className={cx(styles.button,styles.ordersdate,this.state.ordersdate.length>0?styles.ordersdatealarm:null)} onClick={() => this.dateOrdersShow()}>
																<div>
																	<img src={require('../../../Images/icons/calendar_white.svg')} alt="" />
																	Заказы на дату
																	{this.state.ordersdate.length>0 ? <span className={styles.datecounter}>{this.state.ordersdate.length}</span> : null}
																</div>
															</div>
															<div className={cx(styles.button,styles.ordersdate)} onClick={() => this.cancelOrdersShow()}>
																<div>
																	<img src={require('../../../Images/icons/cancel_white.svg')} alt="" />
																	Отмененные заказы
																	{this.state.orderscancel.length>0 ? <span className={cx(styles.datecounter,styles.cancelcounter)}>{this.state.orderscancel.length}</span> : null}
																</div>
															</div>
															{[0].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
															<div className={cx(styles.button,styles.admin)} onClick={() => this.pinCodeShow()}>
																<div>
																	<img src={require('../../../Images/icons/admin_white.svg')} alt="" />
																	Администрирование
																</div>
															</div>
															{[0,1,2].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
														</div>
														<div className={styles.column}>
															<div className={cx(styles.button,styles.orderadd)} onClick={() => this.orderAdd()}>
																<div>
																	<img src={require('../../../Images/icons/add_white.svg')} alt="" />
																	Новый заказ доставка
																</div>
															</div>
															<div className={cx(styles.button,styles.ordersearch)} onClick={() => this.orderSearch()}>
																<div>
																	<img src={require('../../../Images/icons/search_white.svg')} alt="" />
																	Поиск заказа
																</div>
															</div>
															{[0].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
															<div className={cx(styles.button,styles.wharehouse)}>
																<div className={styles.buttonrow} onClick={() => this.logistSwitch()}>
																	<img src={require('../../../Images/icons/route_white.svg')} alt="" />
																	Логист
																</div>
																<div className={styles.buttonrow} onClick={() => this.whareHouseShow()}>
																	<img src={require('../../../Images/icons/wharehouse.svg')} alt="" />
																	Склад
																</div>
															</div>
															<div className={cx(styles.button,styles.shift)}>
																<div className={styles.buttonrow} onClick={() => this.showToKassa(true)}>
																	<img src={require('../../../Images/icons/money_transfer_white.svg')} alt="" />
																	Логисту
																</div>
																<div className={styles.buttonrow} onClick={() => this.cashOutShow()}>
																	<img src={require('../../../Images/icons/kassa_white.svg')} alt="" />
																	Касса
																</div>
															</div>
															{[0,1,2].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
														</div>
													</>
												}
											</>
									)
							)
				}
				{!this.state.isordersearch && !this.state.isfullscreen && !this.state.isordersdate && !this.state.isorderscancel &&
					<div className={styles.column}>
						<div className={styles.header}>
							В работе
							<img src={require('../../../Images/icons/statuses/clock.svg')} alt="" />
							{/*
							{this.state.ordersStats ?
								<div className={styles.currentsummary}>
									<span>Доставка<span>{this.state.ordersStats.delivery.count} / {moneyFormat(this.state.ordersStats.delivery.amount, false)} ₽</span></span>
									<span>Самовывоз<span>{this.state.ordersStats.pickup.count} / {moneyFormat(this.state.ordersStats.pickup.amount, false)} ₽</span></span>
								</div> : null}
							*/}
						</div>
						<div className={styles.allorders}>
							<button onClick={() => this.viewSwitch()}>{this.state.isall ? 'Только самовывоз' : 'Показать все заказы'}</button>
						</div>
						{this.state.orders === null || this.state.orders.length === 0 ? <div className={styles.empty}>Пусто</div> :
							<Scroller className={styles.container} showReload={true} reloadHadler={this.ordersPanelsUpdate}>
								{this.state.orders.filter(f => this.state.isall || f.isPickup).map((v,i) => <div key={i} className={cx(styles.item,this.additionalStatus(v),v.id===this.state.orderid?styles.selected:null)} onClick={() => this.orderSelect(v)}>
									<div className={styles.oneline}>
										<div><b>{v.isPickup ? (v.type === orderType.OPERATOR_HALL ? 'Через оператора в зале ' :  'Самовывоз ') : ''} {v.address}</b></div>
										<div className={styles.amount}>{this.amountGet(v)}</div>
									</div>
									<div className={styles.products}>
										{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
									</div>
									<div className={styles.oneline}>
										<div>Заказ <b>№{v.id}</b></div>
										<div className={styles.date}>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</div>
									</div>
								</div>)}
							</Scroller>
						}
					</div>}
				{this.state.iswharehouse &&
					<div className={styles.ordersearchcontainer}>
						<div className={styles.header}>
							<h4>Склад</h4>
							<Close close={this.state.iswharehouserequest ? this.whareHouseRequestHide.bind(this) : this.whareHouseHide.bind(this)} />
						</div>
						<div className={styles.orderbody}>
							{this.state.store === null ? <div className={cx(styles.orderscontainer,styles.orderscontainerold)}><Loading className={styles.loading} /></div> :
								this.state.iswharehouserequest ?
									<Scroller className={cx(styles.orderscontainer,styles.orderscontainerold,styles.storecontainer)}
										customControls={
											this.state.isrequestsend ?
												<button className={styles.downloadbutton} onClick={() => this.whareHouseRequestDownload()}>
													<img src={require('../../../Images/icons/download.svg')} alt="Скачать" />
													Скачать заявку
												</button>
											:
												<button className={cx(styles.downloadbutton,styles.addbutton)} onClick={() => this.whareHouseRequestAdd()}>
													<img src={require('../../../Images/icons/mark.svg')} alt="Заявка" />
													Создать заявку
												</button>
										}>
											<h4>{this.state.isrequestsend ? 'Заявка' : 'Выберите необходимые продукты/ингредиенты' }</h4>
											{this.state.store.items.length !== 0 ?
												<>
													<ul className={styles.productslistselect}>
														{this.state.isrequestsend ? this.state.store.items.filter(f => this.state.requestrequestids.includes(f.id)).map((v,i) => <li key={i}>
																	<label>
																		<span className={styles.name}>{i+1} {v.cardId ? <>{v.cardName} <b>(п/ф)</b></> : v.ingredientName}</span>
																		<span className={styles.measure}>{moneyFormat(v.price)} ₽ / {v.defaultCount||1} {measureTypeName[v.measureType]}</span>
																	</label>
																</li>)
															:
																this.state.store.items.map((v,i) => <li key={i}>
																	<label>
																		<input type="checkbox" defaultChecked={true} value={v.id} onChange={this.handleFormStore} />
																		<span className={styles.name}>{v.cardId ? <>{v.cardName} <b>(п/ф)</b></> : v.ingredientName}</span>
																		<span className={styles.measure}>{moneyFormat(v.price)} ₽ / {v.defaultCount||1} {measureTypeName[v.measureType]}</span>
																	</label>
																</li>
															)
														}
													</ul>
												</> : null}
									</Scroller>
									:
										<Scroller className={cx(styles.orderscontainer,styles.orderscontainerold,styles.storecontainer)}
											customControls={
												[<button className={styles.downloadbutton} onClick={() => this.whareHouseAlertsDownload()}>
													<img src={require('../../../Images/icons/download.svg')} alt="Скачать" />
													Скачать остатки
												</button>,
												<button className={styles.downloadbutton} onClick={() => this.whareHouseRequestShow()}>
													<img src={require('../../../Images/icons/order.svg')} alt="Заявка" />
													Отправить заявку
												</button>
											]}>
												<>
													{this.state.store.items.length !== 0 ?
														<>
															<h4>Сигналы</h4>
															<ul className={styles.productslist}>
																{this.state.store.items.map((v,i) => <li key={i}>
																	<span className={styles.name}>{i+1}. {v.cardId ? <>{v.cardName} <b>(п/ф)</b></> : v.ingredientName}</span>
																	<span className={styles.measure}>{v.quantity.toFixed(3)} {measureTypeName[v.measureType]} / {v.min} {measureTypeName[v.measureType]}</span>
																</li>)}
															</ul>
														</> : null}
													{this.state.requests && this.state.requests.length !== 0 ?
														<>
															<h4>Необработанные заявки</h4>
															<ul className={styles.requestslist}>
																{this.state.requests.map((v,i) => <li key={i}>
																	<span className={styles.name}>{i+1}. Заявка № {v.id}</span>
																	<span className={styles.measure}>от {dateGet(v.dateCreate)}</span>
																</li>)}
															</ul>

														</> : null}
												</>
										</Scroller>
							}
						</div>
					</div>}
			</div>
			{this.state.modalshowpromptclose && <Modal visible={this.state.modalshowpromptclose} width={'540'} height={'205'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Отменить создание заказа?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Если вы нажмете <b>Да</b>, то данные по заказу не сохранятся!</p>
						<div>
							<button className={styles.button} onClick={() => this.orderCreateClose()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowprompt && <Modal visible={this.state.modalshowprompt} width={'540'} height={'155'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Вы уверены что хотите создать заказ?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div>
							<button className={styles.button} onClick={() => this.orderPaidShow()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowpromptpaid && <Modal visible={this.state.modalshowpromptpaid} width={'540'} height={'155'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Заказ оплачен?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div>
							<button className={styles.button} onClick={() => this.orderPaid(true, orderPaymentType.CARD)}>Карта</button>
							<button className={cx(styles.button,styles.button2)} onClick={() => this.orderPaid(true, orderPaymentType.CASH)}>Наличные</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderPaid(false)}>Оплата курьеру</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowpromptpaidhall && <Modal visible={this.state.modalshowpromptpaidhall} width={'540'} height={'155'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Способ оплаты</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div>
							<button className={styles.button} onClick={() => this.orderPaid(true, orderPaymentType.CARD)}>Карта</button>
							<button className={cx(styles.button,styles.button2)} onClick={() => this.orderPaid(true, orderPaymentType.CASH)}>Наличные</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowpromptcalc && <Modal visible={this.state.modalshowpromptcalc} width={'760'} height={'650'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Сверка за день</h4>
					</div>
					<div className={styles.calccontainer}>
						<div className={styles.form}>
							<div className={styles.sum}>{moneyFormat(this.calcTotalGet())} ₽</div>
							<div className={styles.column}>
								<input type="text" maxLength={7} readOnly={true} value={this.state.moneyCash} onFocus={this.moneyCashFocus} className={cx(styles.input,this.state.moneyCashFocus?styles.focus:null)} />
								<span className={styles.placeholder}>Наличные в кассе</span>
							</div>
							<div className={styles.column}>
								<input type="text" maxLength={7} readOnly={true} value={this.state.moneyPOS} onFocus={this.moneyPOSFocus} className={cx(styles.input,this.state.moneyPOSFocus?styles.focus:null)} />
								<span className={styles.placeholder}>Оплата через терминал</span>
							</div>
						</div>
						<div className={styles.keypad}>
							<div className={styles.num} onClick={() => this.numberSet(7)}>7</div>
							<div className={styles.num} onClick={() => this.numberSet(8)}>8</div>
							<div className={styles.num} onClick={() => this.numberSet(9)}>9</div>
							<div className={styles.num} onClick={() => this.numberSet(4)}>4</div>
							<div className={styles.num} onClick={() => this.numberSet(5)}>5</div>
							<div className={styles.num} onClick={() => this.numberSet(6)}>6</div>
							<div className={styles.num} onClick={() => this.numberSet(1)}>1</div>
							<div className={styles.num} onClick={() => this.numberSet(2)}>2</div>
							<div className={styles.num} onClick={() => this.numberSet(3)}>3</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberSet(0)}>0</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberSet('.')}>.</div>
							<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.numberSet()}>Удл</div>
						</div>
					</div>
					<div className={styles.calccontrols}>
						<button onClick={() => this.calcReport()} className={styles.btn}>Показать расчет</button>
					</div>
					{this.state.moneyTotal ?
						<div className={styles.calcresults}>
							<div>
								<span>Сумма за день</span>
								<span>{moneyFormat(this.state.moneyTotal.all)} ₽</span>
							</div>
							<div>
								<span>Онлайн</span>
								<span>{moneyFormat(this.state.moneyTotal.online)} ₽</span>
							</div>
							<div>
								<span>Прошлые заказы</span>
								<span>{moneyFormat(this.state.moneyTotal.old)} ₽</span>
							</div>
							<div>
								<span>Терминал</span>
								<span>{moneyFormat(this.state.moneyTotal.terminal)} ₽</span>
							</div>
							<div>
								<span>Касса</span>
								<span>{moneyFormat(this.state.moneyTotal.cash)} ₽</span>
							</div>
							<div className={cx(styles.result,this.state.moneyTotal.result<0?styles.resultplus:null)}>
								<span>Результат</span>
								<span>{moneyFormat(this.state.moneyTotal.result)} ₽</span>
							</div>
						</div> : null}
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpromptcancel && <Modal visible={this.state.modalshowpromptcancel} width={'600'} height={'160'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Отменить заказ?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div>
							<button className={styles.button} onClick={() => this.orderCancelSave()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpromptlogist && <Modal visible={this.state.modalshowpromptlogist} width={'600'} height={'160'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Передать заказ логисту?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div>
							<button className={styles.button} onClick={() => this.orderLogistSave()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpromptdayshiftclose && <Modal visible={this.state.modalshowpromptdayshiftclose} width={'600'} height={'220'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Закрыть смену?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Если вы нажмете <b>Да</b>, то будут автоматически напечатаны все открытые фискальные чеки!</p>
						<div>
							<button className={styles.button} onClick={() => this.shiftClose()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpromptdayshiftclosefinish && <Modal visible={this.state.modalshowpromptdayshiftclosefinish} width={'600'} height={'220'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Смена закрыта!</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Дождитесь когда все фискальные чеки распечатаются и можете перегрузить приложение</p>
						<div>
							<button className={styles.button} onClick={() => this.shiftCloseSet()}>Понятно</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowreport && <Modal visible={this.state.modalshowreport} width={'600'} height={'710'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Суточный отчет</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Отчет по точке за <b>{dateGet(dateNow(), {yearHide:true,showMonthFullName:true,showTime:false})}</b> на <b>{dateGet(dateNow(), {onlyTime:true})}</b></p>
						{this.state.dailyReport ?
							<div className={styles.dailyreport}>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>В зале</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.hall.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.hall.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Онлайн. Оплачено</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.onlinepaid.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.onlinepaid.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Онлайн. Оплачено. Доставка</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.onlinepaiddelivery.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.onlinepaiddelivery.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Онлайн. Оплачено. Самовывоз</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.onlinepaidpickup.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.onlinepaidpickup.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Онлайн. QR</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.onlineqrdelivery.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.onlineqrdelivery.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Онлайн. Курьеру. Доставка</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.onlinecashdelivery.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.onlinecashdelivery.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Онлайн. Не оплачено. Самовывоз</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.onlinecashpickup.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.onlinecashpickup.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Оператор. Доставка</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.operatordelivery.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.operatordelivery.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Оператор. Самовывоз</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.operatorpickup.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.operatorpickup.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Прошлые заказы на сегодня</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.old.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.old.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Отмена</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.cancel.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.cancel.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Возврат</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.refund.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.refund.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Наличные в терминале</span>
									<div className={styles.cell}>
										<span>За день</span>
										<span>{moneyFormat(this.state.dailyReport.terminal.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Долг курьеров</span>
									<div className={styles.cell}>
										<span>За день</span>
										<span>{moneyFormat(this.state.dailyReport.terminalInWork.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Наличные в кассе</span>
									<div className={styles.cell}>
										<span>За день</span>
										<span>{moneyFormat(this.state.dailyReport.kassa.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>Прочие продукты</span>
									<div className={styles.cell}>
										<span>{this.state.dailyReport.others.count} шт.</span>
										<span>{moneyFormat(this.state.dailyReport.others.amount)} ₽</span>
									</div>
								</div>
								<div className={cx(styles.row,styles.rowoneline)}>
									<span className={styles.title}>ИТОГО</span>
									<div className={styles.cell}>
										<span><b>{this.state.dailyReport.all.count} шт.</b></span>
										<span><b>{moneyFormat(this.state.dailyReport.all.amount)} ₽</b></span>
									</div>
								</div>
							</div> : null}
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowsectionsreport && <Modal visible={this.state.modalshowsectionsreport} width={'600'} height={'300'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={cx(styles.header,styles.print)}>
						<h4>Отчет по станциям</h4>
						<button className={cx(styles.printbutton)} onClick={() => this.sectionsReportPrint()}>
							Печатать
						</button>
					</div>
					<div className={styles.promptcontainer}>
						{this.state.sectionsreport ?
							<div className={styles.dailyreport}>
								{this.state.sectionsreport.map((v,i) => <div key={i} className={styles.row}>
									<div className={styles.cell}>
										<span className={styles.title}>{v.name}</span>
										<span>{moneyFormat(v.amount, false)} ₽</span>
									</div>
								</div>)}
							</div>
						: 'Нет данных'}
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpin && <Modal visible={this.state.modalshowpin} width={'330'} height={'540'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Введите ПИН-код</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div className={styles.cancelinput}>
							<input type="password" placeholder="ПИН-код" maxLength={4} readOnly={true} value={this.state.pin} onFocus={this.pinFocus} className={cx(styles.input,this.state.pinFocus?styles.focus:null)} />
						</div>
						<div className={styles.keypadpin}>
							<div className={styles.num} onClick={() => this.passwordSet(7)}>7</div>
							<div className={styles.num} onClick={() => this.passwordSet(8)}>8</div>
							<div className={styles.num} onClick={() => this.passwordSet(9)}>9</div>
							<div className={styles.num} onClick={() => this.passwordSet(4)}>4</div>
							<div className={styles.num} onClick={() => this.passwordSet(5)}>5</div>
							<div className={styles.num} onClick={() => this.passwordSet(6)}>6</div>
							<div className={styles.num} onClick={() => this.passwordSet(1)}>1</div>
							<div className={styles.num} onClick={() => this.passwordSet(2)}>2</div>
							<div className={styles.num} onClick={() => this.passwordSet(3)}>3</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.passwordSet(0)}>0</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.passwordSet('.')}></div>
							<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.passwordSet()}>Удл</div>
						</div>
						<div className={styles.control}>
							<button className={cx(styles.button,styles.buttonwide)} onClick={() => this.pinCodeCheck()}>Вход</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowcashin && <Modal visible={this.state.modalshowcashin} width={'460'} height={'495'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Выберите размен на день</h4>
					</div>
					<div className={cx(styles.promptcontainer,styles.promptcontainercash)}>
						<div className={styles.cashpapercoin}>
							<div className={styles.title}>Купюры</div>
							<div className={styles.cancelinput}>
								<input type="text" readOnly={true} value={`${this.state.cashPaper} ₽`} className={cx(styles.input,styles.focus)} />
							</div>
							<ul>{[1000,2000,3000,4000,5000].map((v,i) => <li key={i} onClick={() => this.cashPaperSet(v)} className={v===this.state.cashPaper?styles.selected:null}>{v} ₽</li>)}</ul>
							<div className={styles.title}>Монеты</div>
							<div className={styles.cancelinput}>
								<input type="text" readOnly={true} value={`${this.state.cashCoin} ₽`} className={cx(styles.input,styles.focus)} />
							</div>
							<ul>{[100,300,500,700,1000].map((v,i) => <li key={i} onClick={() => this.cashCoinSet(v)} className={v===this.state.cashCoin?styles.selected:null}>{v} ₽</li>)}</ul>
						</div>
						<div className={styles.control}>
							<button className={cx(styles.button,styles.buttonwide)} onClick={() => this.cashOpen()}>Открыть кассу</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowcashout && <Modal visible={this.state.modalshowcashout} width={'660'} height={'600'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Закрытие кассы</h4>
					</div>
					<div className={styles.promptcontainerblock}>
						<div className={cx(styles.promptcontainer,styles.promptcontainercash)}>
							<div className={styles.cashpapercoin}>
								<div className={styles.title}>Заказов за день</div>
								<div className={styles.money}>{moneyFormat(this.state.casherOrders)} ₽</div>
								<div className={styles.title}>Сумма оплат картой</div>
								<div className={styles.money}>{moneyFormat(parseFloat(this.state.amountTerminal))} ₽</div>
								<div className={styles.title}>Передано логисту</div>
								<div className={styles.money}>{moneyFormat(this.state.casherLogist)} ₽</div>
								<div className={styles.title}>Сумма от логиста</div>
								<div className={styles.money}>{moneyFormat(this.state.logistCasher)} ₽</div>
								<div className={styles.title}>Сумма размена</div>
								<div className={styles.money}>{moneyFormat(this.state.casherExchange)} ₽</div>
							</div>
							<hr/>
							<div className={styles.cashpapercoin}>
								<div className={styles.title}>Долг</div>
								<div className={styles.money}>{moneyFormat(this.state.casherFinal)} ₽</div>
							</div>
							<div className={styles.control}>
								<button className={cx(styles.button,styles.buttonwide)} onClick={() => this.cashOutSave()}>Закрыть кассу</button>
							</div>
						</div>
						<div className={styles.nums}>
							<div className={styles.title}>Сумма оплат картой</div>
							<div className={styles.cancelinput}>
								<input type="text" placeholder="Сумма" maxLength={7} readOnly={true} value={this.state.amountTerminal} onFocus={this.amountTerminalFocus} autoFocus={true} className={cx(styles.input,this.state.amountTerminalFocus?styles.focus:null)} />
							</div>
							<div className={styles.keypad}>
								<div className={styles.num} onClick={() => this.numberTerminalSet(7)}>7</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(8)}>8</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(9)}>9</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(4)}>4</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(5)}>5</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(6)}>6</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(1)}>1</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(2)}>2</div>
								<div className={styles.num} onClick={() => this.numberTerminalSet(3)}>3</div>
								<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberTerminalSet(0)}>0</div>
								<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberTerminalSet('.')}>.</div>
								<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.numberTerminalSet()}>Удл</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowtokassa && <Modal visible={this.state.modalshowtokassa} width={'320'} height={'500'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Передача средств логисту</h4>
					</div>
					<div className={cx(styles.promptcontainer,styles.promptcontainertransfer)}>
						<div className={styles.cancelinput}>
							<input type="text" placeholder="Сумма" maxLength={7} readOnly={true} value={this.state.amountTransfer} onFocus={this.amountTransferFocus} autoFocus={true} className={cx(styles.input,this.state.amountTransferFocus?styles.focus:null)} />
						</div>
						<div className={styles.keypadpin}>
							<div className={styles.num} onClick={() => this.numberTransferSet(7)}>7</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(8)}>8</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(9)}>9</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(4)}>4</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(5)}>5</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(6)}>6</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(1)}>1</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(2)}>2</div>
							<div className={styles.num} onClick={() => this.numberTransferSet(3)}>3</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberTransferSet(0)}>0</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberTransferSet('.')}>.</div>
							<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.numberTransferSet()}>Удл</div>
						</div>
						<div className={styles.control}>
							<button className={cx(styles.button,styles.buttonwide)} onClick={() => this.moneyTransfer()}>Передать</button>
						</div>
					</div>
				</div>
				<Close close={this.showToKassa.bind(this)} />
			</Modal>}
			<Alert />
		</div>;
	}
}

export default withRouter(DashboardOperator);